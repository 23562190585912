import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
import moment from "moment";

const DatePickerField = ({ label, value, onChange, disabled }) => {
  // console.log(value,"gfhgfgdg")
  // const [value, setvalue] = useState(new Date())
const CustomInput = React.forwardRef((props, ref) => (
    <TextField
      fullWidth
      required
      value={value ? moment(value).format("MMMM d, yyyy h:mm aa") : ""}
      onChange={()=> {}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CalendarMonthIcon /> 
            </IconButton>
          </InputAdornment>
        ),
        readOnly:true
      }
      }
      {...props}
      inputRef={ref}
    /> 
  ));
   
  const filterPassedTime = (time) => {

    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      minDate={new Date()}
      filterTime={filterPassedTime}
      showTimeSelect
      timeIntervals={1}
      dateFormat="MMMM d, yyyy h:mm aa" 
      customInput={<CustomInput label={label} />}
      disabled = {disabled}
    />
  );
};

export default DatePickerField;
