import { FormControl, InputAdornment, TextField } from "@mui/material";
import { parseISO } from "date-fns";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker"; 
 
function TimePicker({ value, setValue, label }) { 
  return (
    <DatePicker
      style={{ maxWidth: "700px" }}
      selected={value ? parseISO(moment(value,"HH:mm").toISOString()) : new Date()}
      onChange={(date) => setValue(moment(date).format("HH:mm"))}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption={label} 
      dateFormat="h:mm a"
      customInput={ 
        <FormControl fullWidth>
          <TextField
            value={value?moment(value,"HH:mm").format("h:mm a"): ""}
            onChange={() => {}}
            label={label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span style={{cursor:'pointer'}} role="img" aria-label="Clock">
                    🕒
                  </span>
                </InputAdornment>
              ),
            }}
            autoComplete="off"   
          />
        </FormControl>
      }
    />
  );
}

export default TimePicker;
