export const showAddedDataWithoutApi = (newData, previousData, setData) => {
  console.log(previousData,"acaslkcjaskch")
    setData([newData, ...previousData]);
  };

export const showEditedDataWithoutApi = (index, newData, previousData, setData) => {
    let x = previousData.map((row)=>{
      if(row._id==newData._id){
        return{...newData}
      }else{
        return {...row}
      }
    })
    setData([newData, ...x]);
  } 

export const hideDeletedDataWithoutApi = (index, previousData, setData) => {
  console.log(index,"delete----1")
  console.log(previousData,"delete----2") 
    let x = previousData.filter((row,i) => i!==index);
    setData([...x])
}


