import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
    Drawer,
    Container,
    Select,
    MenuItem,
    InputLabel, 
    FormControl,
    Typography,
    Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { editDashboardAppSetting } from "src/DAL/app_dashboard_setting";

const EditCategories = (props) => {
    const { openEditDrawer, setOpenEditDrawer, trackCategory, getDashboardAppSettingData, dashboardData } = props;

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);

    //=============== The function that submits the form======================
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedCategory.length < 6) {
            enqueueSnackbar("Please choose 6 categories only", { variant: "error" });
            return;
        }

        const reqObj = {
            categories: selectedCategory,
        };
        const result = await editDashboardAppSetting(reqObj);
        if (result.code == 200) {
            getDashboardAppSettingData()
            setOpenEditDrawer(false)
            enqueueSnackbar(result.message, { variant: "success" })
        } else {
            enqueueSnackbar(result.message, { variant: "error" })
            console.log(`Error: ${result.message}`);
        }

    };
    //==========================================================================

    // function that will hit at closing the drawer
    const handleClose = () => {
        setOpenEditDrawer(false);
        setLoading(false);
    };
    //==========================================================================

    const handleChange = (e) => {
        if (e.target.value.length <= 6) setSelectedCategory(e.target.value);
    };

    useEffect(() => {
        const arr = dashboardData?.categories.map((val) => {
          return val._id;
        });
        setSelectedCategory([...arr]);
      }, [openEditDrawer]);

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={openEditDrawer}
            onClose={handleClose}
        >
            <Container sx={{ my: 10, width: 390 }}>
                <Typography variant="h5" sx={{ mt: -5, mb: 5 }}>
                    Edit Categories
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Select Categories
                            </InputLabel>
                            <Select
                                multiple
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCategory}
                                label="Select Categories"
                                onChange={handleChange}
                            >
                                {trackCategory &&
                                    trackCategory.map((val) => {
                                        return <MenuItem value={val._id}>{val.name}</MenuItem>;
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <LoadingButton
                        className="mt-3"
                        loading={loading}
                        type="submit"
                        variant="contained"
                    >
                        Save
                    </LoadingButton>
                </form>
            </Container>
        </Drawer>
    )
}

export default EditCategories