import React, { useEffect } from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Iconify, Page, Scrollbar } from "src/components";
import {
    Card,
    Stack,
    Button,
    Container,
    TableContainer,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import AddCategories from "./components/AddCategories";
import { getDashboardAppSetting } from "src/DAL/app_dashboard_setting";
import EditCategories from "./components/EditCategories";
import { getCategories } from "src/DAL/category";

const AppDashboardSetting = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [dashboardData, setDashboardData] = useState();
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const [openAddDrawer, setOpenAddDrawer] = useState(false);
    const [trackCategory, setTrackCategory] = useState();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
 
    const handleEdit = () => {
        setOpenEditDrawer(true);
    };
    
    const handleAdd = () => {
        setOpenAddDrawer(true);
    };

    const getDashboardAppSettingData = async () => {
        const response = await getDashboardAppSetting();
        if (response.code === 200) {
            setDashboardData(response.setting)
            setOpenBackdrop(false);
            console.log(response, "hhghghghghhg")
            // enqueueSnackbar(response.message, { variant: "success" })
        }
        else {
            enqueueSnackbar(response.message, { variant: "error" });
            setOpenBackdrop(false);
        }
    }
 
    const getTrackCategories = async () => {
        const result = await getCategories();
        if (result.code == 200) {
            console.log("categories fetched", result.category);
            setTrackCategory(result.category);
        } else {
            console.log(`Error: ${result.message}`);
        }
    };

    useEffect(() => {
        getDashboardAppSettingData()
        getTrackCategories()
    }, [])

    return (
        <Page title="Dashboard Setting" style={{ margin: "20px 0" }}>
              <Backdrop
                    sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={openBackdrop}
               >
                   <CircularProgress color="inherit" />
               </Backdrop>
             {!openBackdrop && (
                <Container maxWidth="xl">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    my={1}
                >
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        onClick={() => handleAdd()}
                    >
                        Add New
                    </Button>
                </Stack>
                <Card
                    sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
                >
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <div className="pt-4 px-3">
                                <h5>App Meditation Dashboard</h5>
                            </div>
                            <div className="px-3">
                                <p>Add your favorite categories and see them instantly on your mobile app for a personalized experience.</p>
                            </div>
                            <div className="px-3 mt-3">
                                <h6>Selected Category</h6>
                            </div>
                            <div className="p-3">
                                <ul className="category-list list-unstyled">
                                    {dashboardData &&
                                        dashboardData.categories.map((val, index) => {
                                            return <li>{val.name}</li>;
                                        })}
                                </ul>
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                                <Button
                                    variant="contained"
                                    startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={() => {
                                        handleEdit();
                                    }}
                                >
                                    Edit
                                </Button>
                            </div>
                        </TableContainer>
                    </Scrollbar>
                </Card>
            </Container>
             )}

            {dashboardData && <AddCategories
                openAddDrawer={openAddDrawer}
                setOpenAddDrawer={setOpenAddDrawer}
                trackCategory={trackCategory}
                dashboardData={dashboardData}
                getDashboardAppSettingData={getDashboardAppSettingData}
            />}
            {dashboardData && <EditCategories
                openEditDrawer={openEditDrawer}
                setOpenEditDrawer={setOpenEditDrawer}
                trackCategory={trackCategory}
                dashboardData={dashboardData}
                getDashboardAppSettingData={getDashboardAppSettingData}
            />}

        </Page >
    ) 
}

export default AppDashboardSetting