import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useSnackbar } from "notistack";
import { getQuoteDetail, editQuote } from "src/DAL/quotes";
import { uploadImage } from "src/DAL/uploadFiles";
import { s3baseUrl } from "src/config/config";
import moment from 'moment'
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle
} from "@mui/material";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      "&:active": { bgColor: "red" },
    },
  },
};
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePickerField from "src/components/Datepicker";
import { useAppContext } from "src/hooks";
function EditQuoteDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editQuoteId,
  showEditedDataWithoutApi,
  index,
  categories,
  quotes,
  setQuotes,
  qoutesFunc,
}) {

  const { checked } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imageWidth, setImageWidth] = useState();
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [notificationDate, setNotificationDate] = useState();
  useEffect(() => {
    async function func() {
      try {
        const res = await getQuoteDetail(editQuoteId);
        console.log(res, "caskcjls");
        if (res.code === 200) {
          console.log(res);
          setDescription(res.quotes.description);
          setStatus(res.quotes.status);
          setNotificationDate(new Date(res.quotes.date));
          setImagePreview(s3baseUrl + res.quotes.images.small);
          setNotificationTitle(res.quotes.notification_title);
          setNotificationDescription(res.quotes.notification_description);
          setSelectedCategories(res.quotes.quote_category);
          setData(true);
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (openEditDrawer) {
      func();
    }
  }, [openEditDrawer]);

  //<==========Function that will edit the quote=========>
  const editQuoteApi = async (obj) => {
    if(selectedCategories.length==0){
      enqueueSnackbar("Please select atleast one category",{variant:"error"});
      return;
    }
    try {
      obj = {...obj, quote_category:JSON.stringify(selectedCategories)}
      const res = await editQuote(editQuoteId, obj);
      if (res.code === 200) {
        console.log(res.quotes);
        // showEditedDataWithoutApi(index, res.quotes, quotes, setQuotes);
        qoutesFunc();
        enqueueSnackbar(res.message, { variant: "success" });
        setOpenEditDrawer(false);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Could not edit the Quote, Please try again", {
        variant: "error",
      });
    }
  };
  //======================================================================
  const checkDateValidity = (date) => {
    const diff = moment(date).diff(moment());
    return diff !== NaN && diff > 0 ? true : false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const ifDateIsValid = checkDateValidity(notificationDate);
    // if (!ifDateIsValid) {
    //   enqueueSnackbar("Please select valid date", { variant: "error" });
    //   return;
    // }
    setLoading(true);
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("module", "quotes");
      try {
        const response = await uploadImage(formData);
        if (response.code === 200) {
          await editQuoteApi({
            description,
            notification_description: notificationDescription,
            notification_title: notificationTitle,
            status,
            images: response.paths,
            image_height: imageHeight,
            image_width: imageWidth,
            // date: notificationDate.toISOString(),
          });
        } else {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Something went wrong, Please try again", {
          variant: "error",
        });
      }
    } else {
      await editQuoteApi({
        description,
        notification_description: notificationDescription,
        notification_title: notificationTitle,
        status,
        images: {},
        // date: notificationDate.toISOString(),
      });
    }
    setLoading(false);
  };

  const getScheduleStatus = (date) => {
    if (date) {
      const scheduleDate = new Date(date);
      const currentDate = new Date();

      if (scheduleDate.getTime() > currentDate.getTime()) {
        return false
      } else if (scheduleDate.getTime() <= currentDate.getTime()) {
        return true
      }
    }
    else {
      return true
    }
  }

  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      const img = new Image();
      img.src = URL.createObjectURL(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage));
      img.onload = () => {
        setImageHeight(img.height);
        setImageWidth(img.width);
      };
    }
  }, [selectedImage]);

  //===================================================================

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => setOpenEditDrawer(false)} 
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Edit Quote
        </Typography>
        <div className="mb-3">
          {checked && checked === true ? (
            <Alert severity="info" color="primary">
              <AlertTitle>Sandbox mode is on</AlertTitle>
              Notification scheduling is restricted to <strong>sandbox users</strong> only.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>Sandbox mode is off</AlertTitle>
              Notifications will be scheduled for <strong>all users.</strong>.
            </Alert>
          )}
        </div>
        {data && (
          <form>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="outlined-multiline-static"
                label="Description"
                multiline
                placeholder="Optional"
                rows={4}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Categories</InputLabel>
              <Select
                required
                label="Categories"
                multiple
                value={selectedCategories}
                onChange={(e) => setSelectedCategories(e.target.value)}
                MenuProps={MenuProps}
              >
                {categories &&
                  categories.map((category) => {
                    return (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                {!categories && "No category Found"}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                required
                value={notificationTitle}
                id="outlined-basic"
                label="Notification Title"
                variant="outlined"
                onChange={(e) => setNotificationTitle(e.target.value)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                required
                onChange={(e) => setNotificationDescription(e.target.value)}
                id="outlined-multiline-static"
                label="Notification Description"
                multiline
                rows={4}
                value={notificationDescription}
                //   variant="outlined"
              />
            </FormControl>
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Notification Date"
                  inputFormat="DD-MMM-YYYY"
                  value={notificationDate}
                  disableMaskedInput={true}
                  minDate={new Date()}
                  onChange={(newDate) => setNotificationDate(newDate)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <DatePickerField
                label="Schedule On *"
                value={notificationDate}
                onChange={(date) => setNotificationDate(date)}
                disabled={getScheduleStatus(notificationDate)}
              />
            </FormControl> */}
            <Grid container spacing={2}>
              <Grid item xs={imagePreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button variant="contained" component="label">
                    {!selectedImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                      </>
                    )}
                    <input
                      hidden
                      accept="image/png, image/jpeg"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
                <small>Recommended image size (1080x1920) and file types (jpg,png)</small>
              </Grid>
              {imagePreview && (
                <Grid item xs={4}>
                  <img
                    src={imagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
            <FormControl>
              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                sx={{mt:2}}
              >
                Save
              </LoadingButton>
            </FormControl>
          </form>
        )}
        {!data && (
            <CircularProgress sx={{display:"block",mx:"auto", my:"190px"}} />
         )}
      </Container>
    </Drawer>
  );
}

export default EditQuoteDrawer;
