import { invokeApi } from 'src/utils';

export const getDashboardAppSetting = async () => {
    const requestObj = {
      path: `api/dashboard_setting/get_dashboard_setting`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      },
    };
    return invokeApi(requestObj);
  };

export const editDashboardAppSetting = async (data) => {
    const requestObj = {
      path: `api/dashboard_setting/edit_dashboard_setting`,
      method: 'PUT',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      },
      postData: data
    };
    return invokeApi(requestObj);
  };