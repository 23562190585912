import { invokeApi } from "src/utils";
export const uploadImage = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_module_image`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token")
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
 
export const uploadAudio = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_audio`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token")
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadGeneralFile = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_image_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

