import { invokeApi } from 'src/utils';

export const getReports = async (page, limit, selectedTab, search) => {
  console.log("page", page);
  console.log("limit", limit);
  console.log("selectedTab", selectedTab);

  if (selectedTab === "all") {
    selectedTab = "";
  } else if (selectedTab === "open") {
    selectedTab = "open";
  } else if (selectedTab === "closed") {
    selectedTab = "closed";
  }
 
  const requestObj = {
    path: `api/report_us/get_report_us?page=${page}&limit=${limit}&status=${selectedTab}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const update_reportAction = async () => {
  const requestObj = {
    path: `api/website_setting/update_report_action`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteSupportTicket = async (_id) => {
  const requestObj = {
    path: `api/report_us/delete_report_us/${_id}`,
    method: 'DELETE',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}

export const detailSupport_ticket = async (id) => {
  const requestObj = {
    path: `api/report_us/detail_report_us/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const markReportUs_asRead = async (_id) => {
  const requestObj = {
    path: `api/report_us/mark_report_us_as_read/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const countSupport_ticket = async (id) => {
  const requestObj = {
    path: `api/report_us/mark_messages_as_read/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const action_Status = async () => {
  const requestObj = {
    path: `api/report_us/get_report_us_action_status`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const closeSupportTicket = async (_id) => {
  const requestObj = {
    path: `api/report_us/update_report_us_status/${_id}`,
    method: 'GET',
    headers: {
      'x-sh-auth': localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}

export const _add_support_ticket_comment = async (data, _id) => {
  let requestObj = {
    path: `api/report_us/add_message/${_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
