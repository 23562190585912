// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Grid, Box } from "@mui/material";
// utils
import { fShortenNumber } from "src/utils";
// components
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

// ----------------------------------------------------------------------

SummaryCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard({
  title,
  count,
  icon,
  color = "#000",
  sx,
  ...other
}) {
  return (
      // <Card
      //   sx={{
      //     py: 5,
      //     boxShadow: 0,
      //     textAlign: "center",
      //     color: "#655FB1",
      //     // bgcolor: "#99ceff",
      //     borderRadius:"50%",
      //     bgcolor: (theme) => theme.palette[color].lighter,
      //     width: 230,
      //     height: 230,
      //     ...sx,
      //   }}
      //   {...other}
      // >
      //   <Box sx={{ mx:"auto",justifyContent:"center"}}>
      //       <IconWrapperStyle
      //         sx={{
      //           width:80,
      //           height:80,
      //           color: (theme) => theme.palette[color].dark,
      //           backgroundImage: (theme) =>
      //             `linear-gradient(135deg, ${alpha(
      //               theme.palette[color].dark,
      //               0
      //             )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
      //         }}
      //       >
      //         <Iconify icon={icon} width={50} height={50} sx={{color:"#655FB1"}} />
      //       </IconWrapperStyle>
      //       <Typography variant="h4">{fShortenNumber(count)}</Typography>
      //       <Typography variant="h6" sx={{ opacity: 0.72 }}>
      //         {title}
      //       </Typography>
      //   </Box>
      // </Card>
      <Card
        sx={{
          py: 5,
          boxShadow: 5,
          textAlign: "center",
          // color: (theme) => theme.palette[color].darker,
          bgcolor: "#fff",
          // bgcolor: (theme) => theme.palette[color].lighter,
          width: 300,
          height: 170,
          ...sx,
        }}
        {...other}
      >
        <Grid container rowSpacing={1}>
          <Grid item xs={6} sx={{mt:1}}>
            <Typography variant="h4" sx={{fontWeight:"bold"}}>{fShortenNumber(count)}</Typography>
            <Typography variant="h6" sx={{ opacity: 0.72 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IconWrapperStyle
              sx={{
                width:80,
                height:80,
                // color: (theme) => theme.palette[color].dark,
                bgcolor: color,
                // backgroundImage: (theme) =>
                //   `linear-gradient(135deg, ${alpha(
                //     theme.palette[color].dark,
                //     0
                //   )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
              }}
            >
              <Iconify icon={icon} width={40} height={40} sx={{color:"#fff"}} />
            </IconWrapperStyle>
          </Grid>
        </Grid>
      </Card>
  );
}
