import { useRef, useState } from "react";

import CustomConfirmation from "./CustomConfirmation";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Iconify from "./Iconify";

export default function MoreMenu({
  handleDelete,
  handleEdit,
  handle_add_user_to_sandbox,
  id,
  setEditId,
  user = false,
  setEditIndex,
  index,
  title,
  isDefault = false,
  tooltip="You cannot delete Default Category"
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openClose, setOpenClose] = useState(false);

  const handleOk = () => {
    handleDelete(id, index);
    setIsOpen(false);
  };

  const handleAddToSandbox = (id) => {
    handle_add_user_to_sandbox(id);
    setIsOpen(false);
  };

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleOk}
        title={title}
      />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {!user && location.pathname != "/support_ticket" && (
          <MenuItem
            // component={RouterLink}
            // to="#"
            sx={{ color: "text.secondary" }}
            onClick={() => {
              setIsOpen(false);
              setEditId(id);
              handleEdit();
              setEditIndex(index);
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {location.pathname === "/support_ticket" && (
          <MenuItem className="view_st" sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Iconify icon="mingcute:user-add-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="View"
              onClick={() => handleAddToSandbox(id)}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {isDefault&&
        <div>
          <Tooltip title={tooltip}>
            <MenuItem
              sx={{ color: "lightgray"  }}
              onClick={() => {}}
            // disabled={isDefault}
            >
              <div className="d-flex">
                <ListItemIcon>
                  <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </div>
            </MenuItem>
          </Tooltip>
        </div>
        }
        {!isDefault&&
          <MenuItem
              sx={{ color:  "text.secondary" }}
              onClick={() => 
                  setOpenDelete(true)
                }
            >
              <div className="d-flex">
                <ListItemIcon>
                  <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </div>
            </MenuItem>
        }

        {location.pathname === "/users" && (
          <MenuItem className="sandbox-user" sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Iconify icon="mingcute:user-add-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Add user to sandbox"
              onClick={() => handleAddToSandbox(id)}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
