import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useSnackbar } from "notistack";
import { getCategoryDetail, editCategory } from "src/DAL/category";
import { uploadImage } from "src/DAL/uploadFiles";
import { s3baseUrl } from "src/config/config";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography, 
  Grid,
  CircularProgress
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toInteger } from "lodash";
 
function EditCategoryDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editCategoryId,
  showEditedDataWithoutApi,
  categories,
  setCategories,
  index,
  page,
  rowsPerPage, 
  tracksCtegories,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState(1);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();

  useEffect(() => {
    async function func() {
      try {
        const res = await getCategoryDetail(editCategoryId);
        if (res.code === 200) {
          console.log(res);
          setName(res.category.name);
          setStatus(res.category.status);
          setOrder(res.category.order)
          setImagePreview(s3baseUrl + res.category.images.small);
          setData(true);
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (openEditDrawer) {
      func();
    }
  }, [openEditDrawer]);

  //<==========Function that will edit the category=========>

  const editCategoryApi = async (obj) => {
    try {
      const res = await editCategory(editCategoryId, obj);
      if (res.code === 200) {
        tracksCtegories();
        // showEditedDataWithoutApi(
        //   index,
        //   res.category,
        //   categories,
        //   setCategories
        // );
        // let data = categories
        // data=data.filter(categry => categry._id !== editCategoryId)
        // data.splice(res.category.order-1, 0, res.category)
        // setCategories(data.map((row, index)=>{
        //   return{
        //     ...row , order:page* rowsPerPage+ index+1
        //   }
        // }))
        enqueueSnackbar(res.message, { variant: "success" });
        setOpenEditDrawer(false);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Could not edit the Category, Please try again", {
        variant: "error",
      });
    }
  };

  //======================================================================

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("module", "category");
      try {
        const response = await uploadImage(formData);
        if (response.code === 200) {
          const newCategory = { name, status, images: response.paths, order };
          await editCategoryApi({
            name,
            status,
            images: response.paths, 
            order,
          });
        } else { 
          enqueueSnackbar(response.message, {
            variant: "error",
          });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Something went wrong, Please try again", {
          variant: "error",
        });
      }
    } else {
      await editCategoryApi({ name, status, images: {}, order });
    }
    setLoading(false);
  };

  // function that will preview the image before uploading

  useEffect(() => {
    console.log("here");
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => {setOpenEditDrawer(false);
      setData(false)}}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Edit Category
        </Typography>
        {data && (
          <form>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Order"
                // type="number"
                value={order}
                variant="outlined"
                inputProps={{
                  maxLength: 13,
                  step: "1",
                }}
                onChange={(e) => {if(e.target.value.match(/^$|[0-9]+$/))setOrder(toInteger(e.target.value))}}
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={imagePreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    //   sx={{ width: "50%" }}
                    component="label"
                  >
                    {!selectedImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                      </>
                    )}
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
              </Grid>
              {imagePreview && (
                <Grid item xs={4}>
                  <img
                    src={imagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
            <FormControl> 
              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </FormControl>
          </form>
        )}
        {!data && (
            <CircularProgress sx={{display:"block",mx:"auto", my:"190px"}} />
         )}
      </Container>
    </Drawer>
  );
}

export default EditCategoryDrawer;
