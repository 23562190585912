import { invokeApi } from 'src/utils';
export const getCategories = async (page, limit, search) => {
  if(!search)
  search="";
    const requestObj = {
      path: `api/category/get_categories?page=${page}&limit=${limit}&search=${search}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const getCategoryDetail = async (id) => {
    const requestObj = {
      path: `api/category/category_detail/${id}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const addCategory = async (data) => {
  const requestObj = {
    path : `api/category/add_category`,
    method: 'POST',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const editCategory = async (_id, data) => {
  const requestObj = {
    path : `api/category/edit_category/${_id}`,
    method: 'PUT',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const deleteCategory = async (_id) => {
  const requestObj = {
    path : `api/category/delete_category/${_id}`,
    method: 'DELETE',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}

export const getAllCategories = async () => {
  const requestObj = {
    path : `api/category/get_all_categories`,
    method: 'GET',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}