import { invokeApi } from "src/utils";
export const getSettings = async () => {
  const requestObj = {
    path: `api/website_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const setSettings = async (data) => {
  const requestObj = {
    path: `api/website_setting/edit_website_setting`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData:data
  };
  return invokeApi(requestObj);
};

export const sandboxModeChange = async (data) => {
  const requestObj = {
    path: `api/website_setting/change_app_mode`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData:data
  };
  return invokeApi(requestObj);
};

export const getSandboxModeDetail = async () => { 
  const requestObj = {
    path: `api/website_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const updateAdminProfile = async (data) => {
  const requestObj = {
    path: `api/admin/edit_admin`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};