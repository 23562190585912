// @mui
import { useTheme } from "@mui/material/styles";
import React from "react";
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Backdrop,
  ButtonGroup,
  Button,
  Box,
} from "@mui/material";
// components
import { SummaryCard } from "./";
import { Page } from "src/components";
//hooks
import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { getDashboardDetail } from "src/DAL/dashboard";
import { useSnackbar } from "notistack";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { set } from "lodash";

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "95%",
  },
}));
// ----------------------------------------------------------------------

export default function Dashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const [userCount, setUserCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [adminHabitCount, setAdminHabitCount] = useState(0);
  const [userHabitCount, setUserHabitCount] = useState(0);
  const [goodHabitCount, setGoodHabitCount] = useState(0);
  const [badHabitCount, setBadHabitCount] = useState(0);
  const [moodCount, setMoodCount] = useState(0);
  const [notesCount, setNotesCount] = useState(0);
  const [trackCount, setTrackCount] = useState(0);
  const [quoteCount, setQuoteCount] = useState(0);
  const [loading, setLoading] = useState(true);

  // dialog states & handle
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [to_date, setTo_Date] = React.useState(new Date());
  const [from_date, setFrom_Date] = React.useState(new Date());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getFilteredDashboard();
    setOpen(false);
  };

  const getFilteredDashboard = async () => {
    try {
      const formData = new FormData();
      formData.append("start_date", from_date);
      formData.append("end_date", to_date);
      console.log([...formData], "res-----");
      const response = await getDashboardDetail(formData);
      if (response.code === 200) {
        console.log(response, "res-----");
        setUserCount(response.dashboard.customer_counts);
        setCategoryCount(response.dashboard.category_counts);
        setAdminHabitCount(response.dashboard.admin_habit_counts);
        setUserHabitCount(response.dashboard.user_habit_counts);
        setQuoteCount(response.dashboard.quote_counts);
        setTrackCount(response.dashboard.track_counts);
        setGoodHabitCount(response.dashboard.good_habit_counts);
        setBadHabitCount(response.dashboard.bad_habit_counts);
        setMoodCount(response.dashboard.moods_counts);
        setNotesCount(response.dashboard.notes_counts);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };
  // ---------------------------------------------
  const getDashboard = async () => {
    try {
      const formData = new FormData();
      formData.append("start_date", "");
      formData.append("end_date", "");
      console.log([...formData], "res-----");
      const response = await getDashboardDetail(formData);
      if (response.code === 200) {
        console.log(response, "res-----");
        setUserCount(response.dashboard.customer_counts);
        setCategoryCount(response.dashboard.category_counts);
        setAdminHabitCount(response.dashboard.admin_habit_counts);
        setUserHabitCount(response.dashboard.user_habit_counts);
        setQuoteCount(response.dashboard.quote_counts);
        setTrackCount(response.dashboard.track_counts);
        setGoodHabitCount(response.dashboard.good_habit_counts);
        setBadHabitCount(response.dashboard.bad_habit_counts);
        setMoodCount(response.dashboard.moods_counts);
        setNotesCount(response.dashboard.notes_counts);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };
  const handleForAllData = () => {
    getDashboard();
  };
  const DUMMY_DATA = [
    {
      color: "#ff1a1a",
      title: "Users",
      count: userCount,
      icon: "ant-design:android-filled",
    },
    {
      color: "#00b300",
      title: "Admin Habits",
      count: adminHabitCount,
      icon: "icon-park-outline:sales-report",
    },
    {
      color: "#00b300",
      title: "User Habits",
      count: userHabitCount,
      icon: "icon-park-outline:sales-report",
    },
    {
      color: "#ff9900",
      title: "Tracks",
      count: trackCount,
      icon: "material-symbols:queue-music",
    },
    {
      color: "#68679c",
      title: "Categories",
      count: categoryCount,
      icon: "carbon:category",
    },
    {
      color: "#b55353",
      title: "Quotes",
      count: quoteCount,
      icon: "bi:chat-left-quote-fill",
    },
    {
      color: "#0b4af2",
      title: "Good Habits",
      count: goodHabitCount,
      icon: "arcticons:habitnow",
    },
    {
      color: "#430a1c",
      title: "Bad Habits",
      count: badHabitCount,
      icon: "material-symbols:gpp-bad-outline",
    },
    {
      color: "#cac316",
      title: "Moods",
      count: moodCount,
      icon: "mingcute:emoji-line",
    },
    {
      color: "#16b9ca",
      title: "Notes",
      count: notesCount,
      icon: "uil:notes",
    },
  ];
  const theme = useTheme();
  const { _get_user_profile } = useAppContext();
  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <Page title="Dashboard">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer  + 1,
        }}
        open={loading}
      >
      <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Box className="d-flex justify-content-between" sx={{ my: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button variant="outlined" onClick={() => handleForAllData()}>
              All Time
            </Button>
            <Button
              variant="contained"
              onClick={handleClickOpen}
              startIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </ButtonGroup>
        </Box>

        <Grid className="mb-5" container spacing={5}>
          {DUMMY_DATA.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={6} lg={4} sx={{ mt: 3 }}>
              <SummaryCard
                color={item.color}
                title={item.title}
                count={item.count}
                icon={item.icon}
              />
            </Grid>
          ))}
        </Grid>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"md"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"FILTER"}</DialogTitle>

            <DialogActions>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} style={{ marginTop: 20 }}>
                    <TextField
                      id="date"
                      required
                      label="From"
                      variant="outlined"
                      type="date"
                      value={from_date}
                      onChange={(e) => setFrom_Date(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} style={{ marginTop: 20 }}>
                    <TextField
                      id="date"
                      required
                      label="To"
                      type="date"
                      value={to_date}
                      onChange={(e) => setTo_Date(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <div className="d-flex justify-content-center">
                      <Button color="primary" variant="contained" type="submit">
                        Apply
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 20 }}></div>
                  </Grid>
                </Grid>
              </form>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
