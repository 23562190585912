import { invokeApi } from 'src/utils';
export const getUsers = async (page, rows, search) => {
    const requestObj = {
      path: `api/customer/get_customers?page=${page}&limit=${rows}&text=${search}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const deleteUser = async (id) => {
    const requestObj = {
      path: `api/customer/delete_customer/${id}`,
      method: 'DELETE',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  // sandbox user api
  export const addSandboxUser = async (id) => {
    const requestObj = {
      path: `api/customer/add_customer_to_sandbox/${id}`,
      method: 'POST',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  export const getSandboxUser = async (page,rows,search) => {
    const requestObj = {
      path: `api/customer/get_sandbox_customers?page=${page}&limit=${rows}&text=${search}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  
  export const deleteSandboxUser = async (id) => {
      const requestObj = {
        path: `api/customer/remove_customer_from_sandbox/${id}`,
        method: 'POST',
        headers: {
          'x-sh-auth': localStorage.getItem('token')
        }
      };
      return invokeApi(requestObj);
    };
