import React, { useState, useEffect } from "react";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { uploadImage } from "src/DAL/uploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { s3baseUrl } from "src/config/config";
import { getHabitDetail, editHabit } from "src/DAL/habits";
import { useSnackbar } from "notistack";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl, 
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toInteger } from "lodash";
function EditHabitDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editHabitId,
  change,
  setChange,
  showEditedDataWithoutApi,
  habits,
  setHabits,
  index,
  page,
  rowsPerPage,
  habitsFunc,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  // const [editedHabit, setEditedHabit] = useState();
  const [name, setName] = useState();
  const [type, setType] = useState("");
  const [imagePreview, setImagePreview] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState("");
  // const [date, setDate] = useState();
  // const [days, setDays] = useState([]);
  const [order, setOrder] = useState(1);
  const [loading, setLoading] = useState(false);

  // <--------Fetching Data of the habit to be edited----------->

  useEffect(() => {
    async function func() {
      try {
        console.log(editHabitId);
        const res = await getHabitDetail(editHabitId);
        if (res.code === 200) {
          setName(res.habits.name);
          setType(res.habits.type);
          setStatus(res.habits.status);
          setOrder(res.habits.order);
          setImagePreview(s3baseUrl + res.habits.images.small);
          // setDate(res.data.date);
          // setDays(res.data.days);
          setData(true);
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (openEditDrawer) func();
  }, [openEditDrawer]);

  //====================================================================

  //<==========Function that will edit the habit=========>
 
  const editHabitApi = async (obj) => {
    try {
      const res = await editHabit(editHabitId, obj);
      if (res.code === 200) {
        habitsFunc();
        // showEditedDataWithoutApi(index, res.habit, habits, setHabits);
        // let data= habits
        // data=data.filter(habit => habit._id !== editHabitId)
        // data.splice(res.habit.order-1,0,res.habit)
        // setHabits(data.map((row,index)=>{
        //   return {...row, order:page*rowsPerPage+index+1}
        // }));
        enqueueSnackbar(res.message, { variant: "success" });
        setOpenEditDrawer(false);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) { 
      console.log(error);
      enqueueSnackbar("Could not edit the Habit, Please try again", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    } 
  };
  
  //======================================================================

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("module", "habit");
      const response = await uploadImage(formData);
      if (response.code === 200) {
        await editHabitApi({
          name,
          type,
          status,
          images: response.paths,
          order,
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
      }
    } else {
      await editHabitApi({ name, type, images: {}, status, order });
    }
  };

  // function that will preview the image before uploading

  useEffect(() => {
    console.log("here");
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => {
        setOpenEditDrawer(false);
        setData(false);
      }}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Edit Habit
        </Typography>
        {data && (
          <form>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={"to-do"}>
                  <CheckIcon sx={{ color: "green", mr: 1 }} />
                  To-Do
                </MenuItem>
                <MenuItem value={"not-to-do"}>
                  <CloseIcon sx={{ color: "red", mr: 1 }} />
                  Not To-Do
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <Grid sx={{mb: 2}} container spacing={2}>
              <Grid item xs={imagePreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    //   sx={{ width: "50%" }}
                    component="label"
                  >
                    {!selectedImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedImage && <>Image ({selectedImage.name})</>}
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
                <small>
                  Recommended image size (1024x1024) and file types (jpg,png)
                </small>
              </Grid>
              {imagePreview && (
                <Grid item xs={4} sx={{ mb: 2 }}>
                  <img
                    src={imagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    label="Target Date"
                    inputFormat="DD-MMM-YYYY"
                    value={date}
                    disableMaskedInput
                    onChange={(newDate) => setDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl> */}
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <ToggleButtonGroup
                value={days}
                onChange={(e, v) => setDays(v)}
                aria-label="text formatting"
              >
                <ToggleButton value="monday" aria-label="monday">
                  Mon
                </ToggleButton>
                <ToggleButton value="tuesday" aria-label="tuesday">
                  Tue
                </ToggleButton>
                <ToggleButton value="wednesday" aria-label="wednesday">
                  Wed
                </ToggleButton>
                <ToggleButton value="thusrsday" aria-label="thusrsday">
                  Thu
                </ToggleButton>
                <ToggleButton value="friday" aria-label="friday">
                  Fri
                </ToggleButton>
                <ToggleButton value="saturday" aria-label="saturday">
                  Sat
                </ToggleButton>
                <ToggleButton value="sunday" aria-label="sunday">
                  Sun
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl> */}
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Order"
                // type="number"
                value={order}
                variant="outlined"
                inputProps={{
                  maxLength: 13,
                  step: "1",
                }}
                onChange={(e) => {
                  if (e.target.value.match(/^$|[0-9]+$/))
                    setOrder(toInteger(e.target.value));
                }}
              />
            </FormControl>
            <FormControl>
              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
              >
                Save
              </LoadingButton>
            </FormControl>
          </form>
        )}
        {!data && (
          <CircularProgress
            sx={{ display: "block", mx: "auto", my: "190px" }}
          />
        )}
      </Container>
    </Drawer>
  );
}

export default EditHabitDrawer;
