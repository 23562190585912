import React, { useState } from "react";
import { Page } from "src/components";
import { useSnackbar } from "notistack";
import {
  Container,
  Typography,
  Stack,
  Grid,
  TextField,
  FormControl,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { push_notifications } from "src/DAL/push_notifications";

function PushNotifications() {
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const data = {
        notification_title: title,
        notification_description: description,
      };
      const result = await push_notifications(data);
      if (result.code == 200) {
        setTitle("");
        setDescription("");
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Page title="Push Notification">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Send Notification
        </Typography>
        <Stack spacing={2} direction="row" justifyContent="center">
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    required
                    value={title}
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    value={description}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  sx={{ width: 200 }}
                  loading={loading}
                  type="submit"
                  variant="contained"
                >
                  Send
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Container>
    </Page>
  );
}

export default PushNotifications;
