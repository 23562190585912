import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const users = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
  name: faker.name.findName(),
  // company: faker.company.companyName(),
  // isVerified: faker.datatype.boolean(),
  email: sample([
    'johndoe@xyz.com',
    'xyz@abc.com',
    faker.word.adjective()+'@'+faker.company.bsNoun()+'.com'
  ]),
  status: sample(['active', 'banned']),
  habit_status: sample(['Active', 'Non-Active']),
  type: sample(['Not To-Do', 'To-Do']),
  // role: sample([
  //   'Leader',
  //   'Hr Manager',
  //   'UI Designer',
  //   'UX Designer',
  //   'UI/UX Designer',
  //   'Project Manager',
  //   'Backend Developer',
  //   'Full Stack Designer',
  //   'Front End Developer',
  //   'Full Stack Developer',
  // ]),
}));

export default users;
