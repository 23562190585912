import { useLocation, useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { getAllCategories } from "src/DAL/category";
import { getTracks, deleteTrack, getTrackDetail } from "src/DAL/tracks";
import { s3baseUrl } from "src/config/config";
// import CircularProgress from '@mui/material/CircularProgress';
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  showAddedDataWithoutApi,
  showEditedDataWithoutApi,
  hideDeletedDataWithoutApi,
} from "src/DAL/updateWithoutApi";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Tooltip,
  Divider,
  Badge,
} from "@mui/material";
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
import AddTrackDrawer from "./components/AddTrackDrawer";
import EditTrackDrawer from "./components/EditTrackDrawer";
// modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import moment from "moment";
import FilterTrackDrawer from "./components/FilterTrackDrawer";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 2,
};

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "audio", label: "Audio", alignRight: false },
  { id: "author", label: "Author", alignRight: false },
  { id: "categories", label: "Categories", alignRight: false },
  { id: "schedule-status", label: "Schedule Status", alignRight: false },
  { id: "notifyDate", label: "Schedule Date", alignRight: false },
  { id: "is_sandbox", label: "Sandbox", alignRight: false },
  { id: "is_locked", label: "Availablity", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "", label: "Action", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return (a, b) => -descendingComparator(a, b, orderBy);
}
export default function Tracks() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [categories, setCategories] = useState();
  const [tracks, setTracks] = useState();
  const [count, setCount] = useState();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [editTrackId, setEditTrackId] = useState();
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState(order);
  const [editIndex, setEditIndex] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [loadingBtn, setLoadinBtn] = useState(false);
  const [filterCounts, setFilterCounts] = useState("");
  const [filterTracks, setFilterTracks] = useState(
    localStorage.getItem("filter")
      ? JSON.parse(localStorage.getItem("filter"))
      : {
          category: "",
          availability: "",
          status: "",
        }
  );

  // modal
  const [open, setOpen] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const handleOpen = (image) => {
    setOpen(true);
    setImagePath(image);
  };
  const handleClose = () => setOpen(false);

  // -----------------------------------------------------------------------------

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_track) =>
          _track.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  //categories will be used while adding or editing the track

  const funcCategories = async () => {
    const response = await getAllCategories();
    if (response.code === 200) {
      console.log(response.category);
      setCategories(response.category);
    }
  };

  // tracks will be used while displaying the listing

  const funcTracks = async () => {
    try {
      setLoadinBtn(true);
      const response = await getTracks(
        page,
        rowsPerPage,
        filterName,
        filterTracks
      );
      console.log(filterTracks, "filtersss");
      if (response.code === 200) {
        // setIsLoadingBtn(false);
        localStorage.setItem("filter", JSON.stringify(filterTracks));
        setOpenFilterDrawer(false);
        setTracks(response.tracks);
        setCount(response.count);
        setFilterCounts(
          Object.values(filterTracks).filter((val) => val !== "").length
        );
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadinBtn(false);
      setOpenBackdrop(false);
    }
  };

  //duplicate this for clear filter
  const funcFilterClearTracks = async () => {
    try {
      setIsLoadingBtn(true);
      const response = await getTracks(page, rowsPerPage, filterName, {
        category: "",
        availability: "",
        status: "",
      });
      console.log(filterTracks, "filtersss");
      if (response.code === 200) {
        // setIsLoadingBtn(false);
        localStorage.setItem(
          "filter",
          JSON.stringify({ category: "", availability: "", status: "" })
        );
        setFilterTracks({
          category: "",
          availability: "",
          status: "",
        });
        // setOpenFilterDrawer(false);
        setTracks(response.tracks);
        setCount(response.count);
        setFilterCounts(0);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingBtn(false);
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    setOpenBackdrop(true);
    funcCategories();
  }, []);

  useEffect(() => {
    setTracks();
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterName]);

  useEffect(() => {
    // setOpenBackdrop(true);
    funcTracks();
  }, [page, rowsPerPage, filterName]);

  //=======================================================================================

  const handleEdit = () => {
    setOpenEditDrawer(true);
  };

  const handleAdd = () => {
    setOpenAddDrawer(true);
  };

  const handleFilter = () => {
    setOpenFilterDrawer(true);
  };

  const handleFilterClose = () => {
    setOpenFilterDrawer(false);
    setFilterTracks({
      category: "",
      availability: "",
      status: "",
    });
  };

  const handleFilterChange = (e) => {
    setFilterTracks((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = async (id, index) => {
    try {
      const response = await deleteTrack(id);
      if (response.code === 200) {
        hideDeletedDataWithoutApi(index, tracks, setTracks);
        setCount(count - 1);
        enqueueSnackbar(response.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      if (response.code === 400) {
        navigate("/tracks");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredTracks = tracks
    ? applySortFilter(tracks, getComparator(order, orderBy), filterName)
    : [];

  const isTrackNotFound = filteredTracks.length === 0;

  const getScheduleStatus = (date) => {
    if (date) {
      const scheduleDate = new Date(date);
      const currentDate = new Date();

      if (scheduleDate.getTime() > currentDate.getTime()) {
        return "Schedule";
      } else if (scheduleDate.getTime() <= currentDate.getTime()) {
        return "Published";
      }
    } else {
      return "Published";
    }
  };

  return (
    <Page title="Tracks">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {categories && (
        <>
          <EditTrackDrawer
            tracks={tracks}
            setTracks={setTracks}
            showEditedDataWithoutApi={showEditedDataWithoutApi}
            index={editIndex}
            editTrackId={editTrackId}
            categories={categories}
            openEditDrawer={openEditDrawer}
            setOpenEditDrawer={setOpenEditDrawer}
            funcTracks={funcTracks}
          />
          <AddTrackDrawer
            tracks={tracks}
            setTracks={setTracks}
            count={count}
            setCount={setCount}
            showAddedDataWithoutApi={showAddedDataWithoutApi}
            openAddDrawer={openAddDrawer}
            setOpenAddDrawer={setOpenAddDrawer}
            categories={categories}
            funcTracks={funcTracks}
          />
          <FilterTrackDrawer
            openFilterDrawer={openFilterDrawer}
            setOpenFilterDrawer={setOpenFilterDrawer}
            categories={categories}
            handleFilterChange={handleFilterChange}
            filterTracks={filterTracks}
            handleFilterClose={handleFilterClose}
            funcTracks={funcTracks}
            funcFilterClearTracks={funcFilterClearTracks}
            isLoadingBtn={isLoadingBtn}
            loadingBtn={loadingBtn}
          />
        </>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDelete}
        title="Track"
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <LoadingButton
            className="me-3"
            variant="contained"
            loading={isLoadingBtn}
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={() => {
              funcFilterClearTracks();
            }}
          >
            Clear Filter
          </LoadingButton>
          <Button
            className="me-3"
            variant="contained"
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={() => {
              handleFilter();
            }}
          >
            Filter {filterCounts ? filterCounts : " "}
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }}
          >
            New Track
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Tracks"
            placeholder="Search Tracks by name or ID ... "
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {console.log(tracks, "tracks-------")}
                  {tracks &&
                    tracks
                      // filteredTracks
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        const {
                          _id,
                          name,
                          status,
                          author,
                          images,
                          description,
                          audio,
                          category_name,
                          date,
                          is_locked,
                          is_sandbox,
                          is_published,
                          track_of_the_day,
                        } = row;
                        console.log(tracks, "aaaaaaaaa");
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ maxWidth: 100 }}>
                              <Avatar
                                alt={name}
                                src={s3baseUrl + images.small}
                                onClick={() => handleOpen(images.small)}
                              />
                            </TableCell>
                            <TableCell sx={{ maxWidth: 100 }}>
                              <Tooltip title={name}>
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{ maxWidth: 100 }}>
                              <Tooltip title={description}>
                                <Typography variant="subtitle2" noWrap>
                                  {description}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{ maxWidth: 300 }}>
                              <AudioPlayer
                                src={s3baseUrl + audio}
                                onPlay={(e) => console.log("onPlay-----")}
                              />
                            </TableCell>
                            <TableCell sx={{ maxWidth: 150 }}>
                              <Tooltip title={author}>
                                <Typography variant="subtitle2" noWrap>
                                  {author}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {console.log(
                                category_name,
                                "category_namecategory_name"
                              )}
                              {category_name.map((category, index) => {
                                if (category) {
                                  return (
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      key={index}
                                    >
                                      {category}
                                    </Typography>
                                  );
                                }
                              })}
                            </TableCell>
                            <TableCell sx={{ maxWidth: 200 }}>
                              <Label
                                variant="outlined"
                                noWrap
                                color={
                                  (is_published === true && "success") ||
                                  "error"
                                }
                              >
                                {is_published === true
                                  ? "Published"
                                  : "Schedule"}
                              </Label>
                              {/* {getScheduleStatus(date)} */}
                            </TableCell>
                            <TableCell sx={{ maxWidth: 200 }}>
                              <Typography variant="subtitle2" noWrap>
                                {moment(date).format("lll")}
                              </Typography>
                              {/* <Typography variant="subtitle2" noWrap>
                                {date ? (new Date(date).toLocaleDateString(
                                  undefined,
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )) : ""}
                              </Typography> */}
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitile2">
                                {is_sandbox === true ? "Yes" : "No"}
                              </Typography>
                            </TableCell>

                            {/* <TableCell align='left'>{company}</TableCell>
                          <TableCell align='left'>{role}</TableCell>
                          <TableCell align='left'>{isVerified ? 'Yes' : 'No'}</TableCell> */}
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (is_locked === true && "success") || "error"
                                }
                              >
                                {is_locked === true ? (
                                  <>
                                    <Iconify icon="ic:outline-attach-money" />{" "}
                                    Paid
                                  </>
                                ) : (
                                  <>
                                    <Iconify icon="ic:outline-money-off-csred" />{" "}
                                    Free
                                  </>
                                )}
                              </Label>
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={
                                  (status === true && "success") || "error"
                                }
                              >
                                {status === true ? "Active" : "Inactive"}
                              </Label>
                            </TableCell>

                            <TableCell align="left">
                              <MoreMenu
                                id={_id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                setEditId={setEditTrackId}
                                index={index}
                                setEditIndex={setEditIndex}
                                title="Track"
                                isDefault={track_of_the_day}
                                tooltip="Meditation of the day can't be deleted"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {!openBackdrop && !tracks && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { label: "All", value: -1 }]}
            component="div"
            count={tracks ? count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </div>

            <Divider className="my-2" />
            <img
              src={s3baseUrl + imagePath}
              alt="images"
              style={{ width: "100%" }}
            />
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
