import React from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
export default function CkEditor({ Placeholder, heading, data, setData }) {
  return (
    <CKEditor
      editor={Editor}
      data={data}
      config={{ placeholder: Placeholder }}
      onChange={(e, ed) => {
        setData(ed.getData());
      }}
    />
  );
}
