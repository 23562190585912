import React, { useState, useEffect } from "react";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { uploadImage } from "src/DAL/uploadFiles";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbar } from "notistack";
import {
  Drawer,
  Button, 
  TextField,
  Container,
  Select, 
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { addHabit } from "src/DAL/habits";

function AddHabitDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  showAddedDataWithoutApi,
  habits,
  setHabits,
  count,
  setCount,
  habitsFunc,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState();
  const [type, setType] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState(true);
  const [imagePreview, setImagePreview] = useState();
  const [loading, setLoading] = useState(false);
  // const [date, setDate] = useState(new Date());
  // const [days, setDays] = useState([]);
  // const handleFrequencyChange = (e, value) => {
  //   setDays(value);
  //   console.log(days);
  // };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("module", "habit");
    try {
      const response = await uploadImage(formData);
      if (response.code === 200) {
        // const newHabit = { name, type, images: response.paths, status };
        try {
          const res = await addHabit({ name, type, images: response.paths, status });
          if (res.code === 200) {
            habitsFunc();
            // showAddedDataWithoutApi(res.habit, habits, setHabits);
            setCount(count+1);
            handleClose();
            enqueueSnackbar(res.message, { variant: "success" });
          } else {
            enqueueSnackbar(res.message, { variant: "error" });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }

      // const newHabit = { name, type, status };
      // console.log(newHabit);
      // try {
      //   const response = await addHabit(newHabit);
      //   if (response.code === 200) {
      //     setOpenAddDrawer(false);
      //     enqueueSnackbar(response.message, { variant: 'success' });
      //   }
      //   else{
      //     enqueueSnackbar(response.message, { variant: 'error' });
      //   }
    } catch (error) {
      enqueueSnackbar("Could not add habit, Please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  
  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      console.log(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage));

    }
  }, [selectedImage]);

  //===================================================================
  
  const handleClose = () => {
    setOpenAddDrawer(false);
    setName();
    setType('');
    setSelectedImage();
    setStatus(true);
    setImagePreview();
  }
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Add new Habit
        </Typography>
        <form>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              required
              onChange={(e) => setName(e.target.value)}
              id="outlined-basic"
              label="Name"
              value={name}
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value={"to-do"}>
                <CheckIcon sx={{ color: "green", mr: 1 }} />
                To-Do
              </MenuItem>
              <MenuItem value={"not-to-do"}>
                <CloseIcon sx={{ color: "red", mr: 1 }} />
                Not To-Do
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={imagePreview ? 8 : 12}>
          <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
            <Button
              variant="contained"
              //   sx={{ width: "50%" }}
              component="label"
            >
              {!selectedImage && (
                <>
                  Select Image <PhotoCamera sx={{ ml: 1 }} /> 
                </>
              )}
              {selectedImage && <>Image ({selectedImage.name})</>}
              <input
                hidden
                accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                type="file"
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
            </Button>
          </FormControl>
            <small>Recommended image size (1080x1920) and file types (jpg,png)</small>
          </Grid>
            {imagePreview && (
              <Grid item xs={4}>
                <img src={imagePreview} alt="image" width="100" height="auto" />
              </Grid>
            )}
          </Grid>
          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Target Date"
                  inputFormat="DD-MMM-YYYY"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <ToggleButtonGroup
              value={days}
              onChange={handleFrequencyChange}
              aria-label="text formatting"
            >
              <ToggleButton value="monday" aria-label="monday">
                Mon
              </ToggleButton>
              <ToggleButton value="tuesday" aria-label="tuesday">
                Tue
              </ToggleButton>
              <ToggleButton value="wednesday" aria-label="wednesday">
                Wed
              </ToggleButton>
              <ToggleButton value="thusrsday" aria-label="thusrsday">
                Thu
              </ToggleButton>
              <ToggleButton value="friday" aria-label="friday">
                Fri
              </ToggleButton>
              <ToggleButton value="saturday" aria-label="saturday">
                Sat
              </ToggleButton>
              <ToggleButton value="sunday" aria-label="sunday">
                Sun
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl> */}
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            sx={{mt:2}}
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default AddHabitDrawer;
