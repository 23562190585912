// here we add all base urls and keys

//arham computer
// export const baseUri = "http://192.168.1.213:1300/";

// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
