import { invokeApi } from 'src/utils';
export const getHabits = async (page, rows, search) => {
    const requestObj = {
      path: `api/habit/admin_all_habits?search=${search}&page=${page}&limit=${rows}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const getHabitDetail = async (id) => {
    const requestObj = {
      path: `api/habit/admin_habit_detail/${id}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const addHabit = async (data) => {
  const requestObj = {
    path : `api/habit/admin_add_habit`,
    method: 'POST',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const editHabit = async (_id, data) => {
  const requestObj = {
    path : `api/habit/admin_edit_habit/${_id}`,
    method: 'PUT',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const deleteHabit = async (_id) => {
  const requestObj = {
    path : `api/habit/admin_delete_habit/${_id}`,
    method: 'DELETE',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}