import { invokeApi } from 'src/utils';
 
export const getTracks = async (page, rows, search,filter) => {
    const requestObj = {
      path: `api/track/get_tracks?page=${page}&limit=${rows}&search=${search}&status=${filter.status}&availability=${filter.availability}&category=${filter.category}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const getTrackDetail = async (id) => {
    const requestObj = { 
      path: `api/track/track_detail/${id}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const addTrack = async (data) => {
  const requestObj = {
    path : `api/track/add_track`,
    method: 'POST',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const editTrack = async (_id, data) => {
  const requestObj = {
    path : `api/track/edit_track/${_id}`,
    method: 'PUT',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const deleteTrack = async (_id) => {
  const requestObj = {
    path : `api/track/delete_track/${_id}`,
    method: 'DELETE',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}