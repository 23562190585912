import { invokeApi } from 'src/utils';
export const getDashboardDetail = async (data) => {
    const requestObj = {
      path: `api/dashboard/get_dashboard_data`,
      method: 'POST',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      },
      postData:data
    };
    return invokeApi(requestObj);
  };