import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { uploadImage } from "src/DAL/uploadFiles";
import { addCategory } from "src/DAL/category";
import { useSnackbar } from "notistack";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
function AddCategoryDrawer({ openAddDrawer, setOpenAddDrawer, showAddedDataWithoutApi, categories, setCategories ,  tracksCtegories}) {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState();
  const [status, setStatus] = useState(true);
  const [imagePreview, setImagePreview] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    console.log(selectedImage);
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("module", "category")
    try {
      const response = await uploadImage(formData);
      if (response.code === 200) {
        // setImage(response.paths);
        // console.log(response.path);
        // console.log(image);
        // setLoading(false);
        // if (image) {
          const newCategory = { name, images:response.paths, status };
          try {
            const res = await addCategory(newCategory);
            if (res.code === 200) {
              // showAddedDataWithoutApi(res.category, categories, setCategories); 
              tracksCtegories();
              handleClose();
              enqueueSnackbar(res.message, { variant: "success" });
            } else {
              enqueueSnackbar(res.message, { variant: "error" });
            } 
          } catch (error) {
            // console.log(error);
            enqueueSnackbar("Could not add Category, Please try again.", {
              variant: "error",
            });
          }
        // }
        // else {console.log('no image')}
      } else {
        console.log('no image');
        enqueueSnackbar(response.message, { variant: "error" });
      }

    } catch (error) {
      console.log(error);
      enqueueSnackbar("Could not Upload Image, Please try again.", {
        variant: "error",
      });
    }
    setLoading(false);
  };
  
  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      console.log(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage));
      console.log(imagePreview);
    }
  }, [selectedImage]);

  //===================================================================

  const handleClose = () => {
    setOpenAddDrawer(false);
    setName();
    setStatus(true);
    setSelectedImage();
    setImagePreview();
  }
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Add new Category
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              required
              id="outlined-basic"
              value={name}
              label="Name"
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Non-Active</MenuItem>
            </Select>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={imagePreview ? 8 : 12}>
          <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
            <Button
              variant="contained"
              //   sx={{ width: "50%" }}
              component="label"
            >
              {!selectedImage && (
                <>
                  Select Image <PhotoCamera sx={{ ml: 1 }} />
                </>
              )}
              {selectedImage && <>Image ({selectedImage.name})</>}
              <input
                hidden
                accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                type="file"
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
            </Button>
          </FormControl>
          </Grid>
            {imagePreview && (
              <Grid item xs={4}>
                <img src={imagePreview} alt="image" width="100" height="auto" />
              </Grid>
            )}
          </Grid>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default AddCategoryDrawer;
