import { invokeApi } from "src/utils";
export const push_notifications = async (data) => {
    const requestObj = {
      path: `api/admin/send_notification`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData:data
    };
    return invokeApi(requestObj);
  };
  