import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  //   Tooltip,
  //   IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,

} from "@mui/material";
// component
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------
 
const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  "& legend": {
    display: "none",
  },
}));
 
// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  //   numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  moduleName: PropTypes.string
};

export default function UserListToolbar({
  //   numSelected,
  filterName,
  onFilterName,
  moduleName,
  placeholder,
}) {
  return (
    // <RootStyle
    // sx={{
    //   ...(numSelected > 0 && {
    //     color: 'primary.main',
    //     bgcolor: 'primary.lighter',
    //   }),
    // }}s
    // >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        margin={2}
      >
        <Typography variant="h4">{moduleName}</Typography>

        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={placeholder ? placeholder : `Search ${moduleName}...`}
          sx={{ height: 40}} 
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      </Stack>
      // {
        // numSelected > 0 ? (
        //   <Typography component='div' variant='subtitle1'>
        //     {numSelected} selected
        //   </Typography>
        // ) : (
        // <SearchStyle
        //   value={filterName}
        //   onChange={onFilterName}
        //   placeholder='Search user...'
        //   startAdornment={
        //     <InputAdornment position='start'>
        //       <Iconify
        //         icon='eva:search-fill'
        //         sx={{ color: 'text.disabled', width: 20, height: 20 }}
        //       />
        //     </InputAdornment>
        //   }
        // />
        // )
      // }

      // {/* {numSelected > 0 ? (
      //   <Tooltip title='Delete'>
      //     <IconButton>
      //       <Iconify icon='eva:trash-2-fill' />
      //     </IconButton>
      //   </Tooltip>
      // ) : ""
      // (
      //   <Tooltip title='Filter list'>
      //     <IconButton>
      //       <Iconify icon='ic:round-filter-list' />
      //     </IconButton>
      //   </Tooltip>
      // )
      // } */}
    // </RootStyle>
  );
}
