import React , {useState} from 'react';
import { Badge, Button, Container, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Iconify } from 'src/components';
import { LoadingButton } from '@mui/lab';
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function FilterTrackDrawer({setOpenFilterDrawer,openFilterDrawer, categories,filterTracks,handleFilterChange,handleFilterClose,funcTracks,funcFilterClearTracks,isLoadingBtn,loadingBtn}) {

  return (
  <Drawer
    variant="temporary"
    anchor="right"
    open={openFilterDrawer}
    onClose={handleFilterClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Filter Tracks
        </Typography>
        <form>
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
              required
              // multiple
              label="Categories"
              name="category"
              value={filterTracks.category}
              onChange={handleFilterChange}
              MenuProps={MenuProps}
            >
              {categories.length === 0 && (
                <MenuItem disabled>
                  <em>No Category found</em>
                </MenuItem>
              )}
              {categories &&
                categories.map((category) => {
                  return (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Availibility</InputLabel>
            <Select
              required
              label="Availibilty"
              name="availability"
              value={filterTracks.availability}
              onChange={handleFilterChange}
            >
              <MenuItem value="free">Free</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
            </Select>
          </FormControl> 
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              label="Status"
              name="status"
              value={filterTracks.status}
              onChange={handleFilterChange}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
         <Stack 
          direction="column"
          alignItems="left"
          justifyContent="flex-start"
          my={1}
          >
         <LoadingButton
            className="me-3"
            loading={loadingBtn}
            variant="contained"
            startIcon={<Iconify icon="mdi:filter" />}
            style={{marginBottom:'15px'}}
            onClick={() => {
              funcTracks();
            }}
          >
            Filter
          </LoadingButton>
          <LoadingButton
            className="me-3"
            loading={isLoadingBtn}
            variant="contained"
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={() => {
              funcFilterClearTracks();
            }}
          >
           Clear Filter
          </LoadingButton>
         </Stack>
        </form>
      </Container>
  </Drawer>
  )
}

export default FilterTrackDrawer
