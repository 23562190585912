import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { uploadImage } from "src/DAL/uploadFiles";
import { addQuote } from "src/DAL/quotes";
import { useSnackbar } from "notistack";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography, 
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePickerField from "src/components/Datepicker";
import { useAppContext } from "src/hooks";
function AddQuoteDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  showAddedDataWithoutApi,
  quotes,
  categories,
  setQuotes,
  count,
  setCount,
  recall,
  setRecall, 
}) {

  const { checked } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [notificationDate, setNotificationDate] = useState(new Date(moment().add(5, "minutes")));
  const checkDateValidity = (date) => {
    const diff = moment(date).diff(moment());
    return diff !== NaN && diff > 0 ? true : false;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const ifDateIsValid = checkDateValidity(notificationDate);
    if (selectedCategories.length == 0) {
      enqueueSnackbar("Please select atleast one category", { variant: "error" })
      return;
    }
    if (!ifDateIsValid) {
      enqueueSnackbar("Please select valid date", { variant: "error" });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("module", "quotes");
    try {
      const response = await uploadImage(formData);
      console.log(response);
      if (response.code === 200) {
        try {
          const res = await addQuote({
            description,
            images: response.paths,
            status,
            image_height: imageHeight,
            image_width: imageWidth,
            notification_title: notificationTitle,
            notification_description: notificationDescription,
            date: notificationDate.toISOString(),
            quote_category: JSON.stringify(selectedCategories)
          });
          console.log(res);
          if (res.code === 200) {
            console.log(res, "ressssssssssssssssss");
            showAddedDataWithoutApi(res.Quotes, quotes, setQuotes);
            setCount(count + 1);
            setRecall(!recall)
            enqueueSnackbar(res.message, { variant: "success" });
            handleClose();
          } else {
            enqueueSnackbar(res.message, { variant: "error" });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      const img = new Image();
      img.src = URL.createObjectURL(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage));
      img.onload = () => {
        setImageHeight(img.height);
        setImageWidth(img.width);
      };
    }
  }, [selectedImage]);

  //===================================================================
  const handleClose = () => {
    setOpenAddDrawer(false);
    setDescription();
    setStatus(true);
    setSelectedImage();
    setImagePreview();
    setImageHeight();
    setImageWidth();
    setNotificationTitle("");
    setNotificationDescription("");
    setNotificationDate("");
    setSelectedCategories([])
  };

  useEffect(()=>{
    if(openAddDrawer){
      setNotificationDate(new Date(moment().add(5, "minutes")));
    }
  },[openAddDrawer])
  
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
    >
      <Container sx={{ width: 390 }}>
        <Typography variant="h4" sx={{ margin: 2 }}>
          Add new Quote
        </Typography>
        <div className="mb-3">
          {checked && checked === true ? (
            <Alert severity="info" color="primary">
              <AlertTitle>Sandbox mode is on</AlertTitle>
              Notification scheduling is restricted to <strong>sandbox users</strong> only.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>Sandbox mode is off</AlertTitle>
              Notifications will be scheduled for <strong>all users.</strong>.
            </Alert>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }} disabled="true">
            <TextField
              onChange={(e) => setDescription(e.target.value)}
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              value={description}
              placeholder="Optional"
            //   variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
              required
              multiple
              label="Categories"
              value={selectedCategories}
              onChange={(e) => {
                setSelectedCategories(e.target.value);
              }}
              MenuProps={MenuProps}
            >
              {categories.length === 0 && (
                <MenuItem disabled>
                  <em>No Category found</em>
                </MenuItem>
              )}
              {categories &&
                categories.map((category) => {
                  return (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              required
              value={notificationTitle}
              id="outlined-basic"
              label="Notification Title"
              variant="outlined"
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              required
              onChange={(e) => setNotificationDescription(e.target.value)}
              id="outlined-multiline-static"
              label="Notification Description"
              multiline
              rows={4}
              value={notificationDescription}
            //   variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <DatePickerField
              label="Schedule On *"
              value={notificationDate}
              onChange={(date) => setNotificationDate(date)}
              InputProps={{readOnly: true,}}
            />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={imagePreview ? 8 : 12}>
              <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                <Button variant="contained" component="label">
                  {!selectedImage && (
                    <>
                      Select Image <PhotoCamera sx={{ ml: 1 }} />
                    </>
                  )}
                  {selectedImage && (
                    <>
                      <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                    </>
                  )}
                  <input
                    hidden
                    required
                    accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                    type="file"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                </Button>
              </FormControl>
              <small>Recommended image size (1080x1920) and file types (jpg,png)</small>
            </Grid>
            {imagePreview && (
              <Grid item xs={4}>
                <img src={imagePreview} alt="image" width="100" height="auto" />
              </Grid>
            )}
          </Grid>
          <LoadingButton
            loading={loading}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default AddQuoteDrawer;
