import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import {
  showAddedDataWithoutApi,
  showEditedDataWithoutApi,
  hideDeletedDataWithoutApi,
} from "src/DAL/updateWithoutApi";

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
import AddCategoryDrawer from "./components/AddCategoryDrawer";
import EditCategoryDrawer from "./components/EditCategoryDrawer";
import {
  deleteQuoteCategory,
  getQuoteCategories,
} from "src/DAL/quoteCategories";

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  { id: "quotes-count", label: "Quotes", alignRight: false, alignCenter: true },
  { id: "status", label: "Status", alignRight: false },
  { id: "", label: "Action", alignRight: true },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(orderBy) {
  return (a, b) => -descendingComparator(a, b, orderBy);
}

export default function QuoteCategories() {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [categories, setCategories] = useState();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState();
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState(order);
  const [editIndex, setEditIndex] = useState();
  const [count, setCount] = useState(0);

  function applySortFilter(array, comparator, query) {
    if (categories) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        // const order = comparator(a[0], b[0]);
        // if (order !== 0) return order;
        return a[1] - b[1];
      });
      if (query) {
        return filter(
          array,
          (_category) =>
            _category.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }
      return stabilizedThis.map((el) => el[0]);
    }
  }

  const quotesCategories = async () => {
    const response = await getQuoteCategories(page, rowsPerPage, filterName);
    if (response.code === 200) {
      setCategories(response.quote_category);
      setCount(response.count);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setOpenBackdrop(false);
  };

  useEffect(() => {
    quotesCategories();
    // setOpenBackdrop(false);
  }, [page, rowsPerPage, filterName]);

  useEffect(() => {
    setCategories([]);
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  const handleEdit = () => {
    setOpenEditDrawer(true);
  };
  const handleAdd = () => {
    setOpenAddDrawer(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDelete = async (id, index) => {
    try {
      const response = await deleteQuoteCategory(id);
      if (response.code === 200) {
        hideDeletedDataWithoutApi(index, categories, setCategories);
        enqueueSnackbar(response.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  const filteredCategories = categories
    ? applySortFilter(categories, getComparator(orderBy), filterName)
    : [];
  useEffect(() => {
    setPage(0);
  }, [filterName]);
  const isCategoryNotFound = categories?.length === 0;

  return (
    <Page title="Quote Categories">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EditCategoryDrawer
        categories={categories} 
        index={editIndex}
        setCategories={setCategories}
        showEditedDataWithoutApi={showEditedDataWithoutApi}
        openEditDrawer={openEditDrawer}
        setOpenEditDrawer={setOpenEditDrawer}
        editCategoryId={editCategoryId}
        page={page}
        rowsPerPage={rowsPerPage}
        quotesCategories={quotesCategories}
      />
      <AddCategoryDrawer
        categories={categories}
        setCategories={setCategories}
        showAddedDataWithoutApi={showAddedDataWithoutApi}
        openAddDrawer={openAddDrawer}
        setOpenAddDrawer={setOpenAddDrawer}
        quotesCategories={quotesCategories}
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }}
          >
            New Category
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Quote Categories"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {categories &&
                    categories
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        const {
                          _id,
                          name,
                          status,
                          images,
                          quoteCount,
                          order,
                          is_default,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={name}
                                  src={s3baseUrl + images.small}
                                />
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{order}</TableCell>
                            <TableCell align="center">
                              <Typography variant="subtitle2" noWrap>
                                {quoteCount}
                              </Typography>
                            </TableCell>
                            {/* <TableCell align='left'>{company}</TableCell>
                          <TableCell align='left'>{role}</TableCell>
                          <TableCell align='left'>{isVerified ? 'Yes' : 'No'}</TableCell> */}
                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={
                                  (status === true && "success") || "error"
                                }
                              >
                                {status === true ? "Active" : "Inactive"}
                              </Label>
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                id={_id}
                                index={index}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                setEditId={setEditCategoryId}
                                setEditIndex={setEditIndex}
                                title="Category"
                               
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {isCategoryNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
