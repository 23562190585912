import { filter } from "lodash";
import { useState, useEffect } from "react";
import { deleteSandboxUser, getSandboxUser } from "src/DAL/users";
import { useSnackbar } from "notistack";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// components
import { Page, Label, Scrollbar, SearchNotFound } from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import { hideDeletedDataWithoutApi } from "src/DAL/updateWithoutApi";

// mock
//
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "is_private_email", label: "Is Private Email", alignRight: false },
  { id: "is_sandbox", label: "Is Sandbox", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function ViewSandboxUser() {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [users, setUsers] = useState();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [sandboxDetail, setSandboxDetail] = useState();

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function applySortFilter(array, comparator, query) {
    if (sandboxDetail) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      if (query) {
        return filter(
          array,
          (_user) =>
            _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }
      return stabilizedThis.map((el) => el[0]);
    }
  }
  const getSandboxDetail = async () => {
    const response = await getSandboxUser(page, rowsPerPage, filterName);
    console.log(response, "sandasdasdasdbox----");
    if (response.code === 200) {
      setSandboxDetail(response.customer);
      setCount(response.count);
      setOpenBackdrop(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getSandboxDetail();
  }, [filterName, page, rowsPerPage]);

  useEffect(() => {
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterName]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = users.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDelete = async (id, index) => {
    try {
      const response = await deleteSandboxUser(id);
      if (response.code === 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        getSandboxDetail();
        // setCount(count - 1);
        // hideDeletedDataWithoutApi(index, users, setUsers);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error);
    }
  };
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = sandboxDetail
    ? applySortFilter(sandboxDetail, getComparator(order, orderBy), filterName)
    : [];

  const isUserNotFound = filteredUsers.length === 0;

  // const handleNav = () => {
  //   navigate("/users/add-user");
  // };

  return (
    <Page title="User">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        ></Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            // numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Sandbox Users"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {sandboxDetail &&
                    sandboxDetail.map((row, index) => {
                      const {
                        name,
                        user_id,
                        is_private_email,
                        is_sandbox,
                        status,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {page * rowsPerPage + index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              <Label
                                variant="outlined"
                                color={is_private_email ? "success" : "error"}
                              >
                                {is_private_email.toString()}
                              </Label>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              <Label
                                variant="outlined"
                                color={is_sandbox ? "success" : "error"}
                              >
                                {is_sandbox.toString()}
                              </Label>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              <Label
                                variant="outlined"
                                color={status ? "success" : "error"}
                              >
                                {status.toString()}
                              </Label>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <IconButton aria-label="delete">
                              <DeleteIcon
                                onClick={() => handleDelete(user_id._id)}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={sandboxDetail ? count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
