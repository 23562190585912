import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { getQuotes, deleteQuote } from "src/DAL/quotes";
import { baseUri, s3baseUrl } from "src/config/config";
import {
  showAddedDataWithoutApi,
  showEditedDataWithoutApi,
  hideDeletedDataWithoutApi,
} from "src/DAL/updateWithoutApi";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
import AddQuoteDrawer from "./components/AddQuoteDrawer";
import EditQuoteDrawer from "./components/EditQuoteDrawer";
import moment from "moment";
import { getAllQuoteCategories } from "src/DAL/quoteCategories";
// modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 2,
};

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "cateories", label: "Categories", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "date", label: "Created at", alignRight: false },
  { id: "schedule-status", label: "Schedule Status", alignRight: false },
  { id: "is_sandbox", label: "Sandbox", alignRight: false },
  { id: "notify_date", label: "Schedule Date", alignRight: false },
  { id: "", label: "Action", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
} 

function getComparator(order, orderBy) {
  return (a, b) => descendingComparator(a, b, order, orderBy);
}
export default function Quotes() {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [quotes, setQuotes] = useState();
  const [categories, setCategories] = useState();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [editQuoteId, setEditQuoteId] = useState();
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState(order);
  const [editIndex, setEditIndex] = useState();
  const [count, setCount] = useState(0);
  const [recall, setRecall] = useState(false);
  // modal
  const [open, setOpen] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const handleOpen = (image) => {
    setOpen(true);
    setImagePath(image);
  };
  const handleClose = () => setOpen(false);
  // -----------

  //===============================================

  // function applySortFilter(array, comparator, query) {
  //   if (quotes) {
  //     const stabilizedThis = array.map((el, index) => [el, index]);
  //     stabilizedThis.sort((a, b) => {
  //       const order = comparator(a[0], b[0]);
  //       if (order !== 0) return order;
  //       return a[1] - b[1];
  //     });
  //     if (query) {
  //       return filter(
  //         array,
  //         (_quotes) =>
  //           _quotes.description.toLowerCase().indexOf(query.toLowerCase()) !==
  //           -1
  //       );
  //     }
  //     return stabilizedThis.map((el) => el[0]);
  //   }
  // }
  function applySortFilter(array, comparator, query) {
    if (quotes) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);  // Now passing the correct parameters
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      if (query) {
        return filter(
          array,
          (_quotes) =>
            _quotes.description.toLowerCase().indexOf(query.toLowerCase()) !==
            -1
        );
      }
      return stabilizedThis.map((el) => el[0]);
    }
  }

  useEffect(() => {
    const funcCategories = async () => {
      const response = await getAllQuoteCategories();
      if (response.code === 200) {
        setCategories(response.quote_category);
      } 
    };
    funcCategories(); 
  }, []);

  const qoutesFunc = async () => {
    // if(filterName!=='') {
    //   setPage(0);
    //   setCount(0);
    // }
    const response = await getQuotes(page, rowsPerPage, filterName);
   
    if (response.code === 200) {
      setQuotes(response.quotes);
      setCount(response.count);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setOpenBackdrop(false);
  };
  useEffect(() => {
    qoutesFunc();
  }, [filterName, page, rowsPerPage, recall]);

  useEffect(() => {
    setQuotes([]);
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterName]);

  const handleEdit = () => {
    setOpenEditDrawer(true);
  };
  const handleAdd = () => {
    setOpenAddDrawer(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDelete = async (id, index) => {
    try {
      const response = await deleteQuote(id);
      if (response.code === 200) {
        // let x = quotes.filter((row,i) => i!==index);
        // setQuotes([...x]);
        setCount(count - 1);
        hideDeletedDataWithoutApi(index, quotes, setQuotes);
        enqueueSnackbar(response.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getScheduleStatus = (date) => {

    const scheduleDate = new Date(date);

    const currentDate = new Date();

    if (scheduleDate.getTime() > currentDate.getTime()) {
      return "Schedule"
    } else if (scheduleDate.getTime() <= currentDate.getTime()) {
      return "Published"
    }
  }

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quotes.length) : 0;
 
  const filteredQuotes = quotes
    ? applySortFilter(quotes, getComparator(order, orderBy), filterName)
    : [];

  const isQuoteNotFound = filteredQuotes.length === 0;
 
  return (
    <Page title="Quotes">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {categories && (
        <>
          <EditQuoteDrawer
            quotes={quotes}
            setQuotes={setQuotes}
            showEditedDataWithoutApi={showEditedDataWithoutApi}
            index={editIndex}
            openEditDrawer={openEditDrawer}
            setOpenEditDrawer={setOpenEditDrawer}
            editQuoteId={editQuoteId}
            categories={categories}
            qoutesFunc={qoutesFunc}
          />
          <AddQuoteDrawer 
            quotes={quotes}
            categories={categories}
            count={count}
            setCount={setCount}
            setQuotes={setQuotes}
            showAddedDataWithoutApi={showAddedDataWithoutApi}
            openAddDrawer={openAddDrawer}
            setOpenAddDrawer={setOpenAddDrawer}
            recall={recall}
            setRecall={setRecall}
          />
        </>
      )}
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }} 
          >
            New Quote
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Quotes"
            placeholder="Search Quotes by name or ID..."
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {quotes &&
                    // filteredQuotes
                    quotes
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        const {
                          _id,
                          description,
                          status,
                          images,
                          order,
                          createdAt,
                          date,
                          quote_category,
                          is_sandbox,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell>
                              <Typography variant="subtitle2">
                                {page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={description}
                                  src={s3baseUrl + images.small}
                                  onClick={() => handleOpen(images.small)}
                                />
                              </Stack>
                            </TableCell>
                            <TableCell sx={{ maxWidth: 100 }}>
                              <Tooltip title={description}>
                                <Typography variant="subtitle2" noWrap>
                                  {description}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {quote_category.map((category) => {
                                if (category._id) {
                                  return (
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      key={category._id}
                                    >
                                      {category.name + " "}
                                    </Typography>
                                  );
                                }
                              })}
                            </TableCell>
                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={
                                  (status === true && "success") || "error"
                                }
                              >
                                {status === true ? "Active" : "Inactive"}
                              </Label>
                            </TableCell>
                            <TableCell>
                              {new Date(createdAt).toLocaleDateString(
                                undefined,
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Label
                                variant="outlined"
                                color={
                                  (getScheduleStatus(date) === "Published" && "success") || "error"
                                }
                              >
                                {getScheduleStatus(date) === "Published" ? "Published" : "Schedule"}
                              </Label>
                              {/* {getScheduleStatus(date)} */}
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitlte2">
                                {is_sandbox === true ? "Yes" : "No"}
                              </Typography>
                            </TableCell>
                            <TableCell> 
                              {moment(date).format("lll")}
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                id={_id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                setEditId={setEditQuoteId}
                                index={index}
                                setEditIndex={setEditIndex}
                                title="Quote"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {!openBackdrop && !quotes && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={quotes ? count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </div>

            <Divider className="my-2" />
            <img
              src={s3baseUrl + imagePath}
              alt="images"
              style={{ width: "100%" }}
            />
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
