import React, { useState, useEffect } from "react";
import {
  FormControl,
  TextField,
  Button,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/system";
import { useSnackbar } from "notistack";
import { updateAdminProfile } from "src/DAL/settings";
import { uploadGeneralFile } from "src/DAL/uploadFiles";
import { Page } from "src/components";
import { useAppContext } from "src/hooks";
import { s3baseUrl } from "src/config/config";

const Input = styled("input")({
  display: "none",
});
 
const Settings = () => {
  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(profile.image);
  const [oldImage, setOldImage] = useState(profile.image);
  // const [userData, setUserData] = useState({
  //   first_name: profile.first_name,
  //   last_name: profile.last_name,
  //   profile_image: profile.profile_image ? profile.profile_image : "",
  //   status: profile.status,
  // });

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleUpload = async (event) => {
  //   const file = event.target.files[0];
  //   const preview = URL.createObjectURL(file);
  //   setImage(preview);

  //   const formData = new FormData();
  //   formData.append("image", file);

  //   try {
  //     const response = await uploadGeneralFile(formData);
  //     if (response.code === 200) {
  //       setUserData({
  //         ...userData,
  //         profile_image: response.path,
  //       });
  //       setOldImage(response.data);
  //     } else {
  //       enqueueSnackbar(response.message, { variant: "error" });
  //     }
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  

  const handleUpload = async (file) => {
    try {
      const preview = URL.createObjectURL(file);
      // setImage(preview);

      const formData = new FormData();
      formData.append("image", file);

      const response = await uploadGeneralFile(formData);

      if (response.code === 200) {
        setUserData({
          ...userData,
          profile_image: response.path,
        });
        setOldImage(response.path);
        setImage(response.path);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleUpload(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const postData = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      profile_image: userData.profile_image,
      status: userData.status ? userData.status : true,
    };
    try {
      const response = await updateAdminProfile(postData);
      if (response.code === 200) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        localStorage.setItem('user', JSON.stringify(response.adminUser))
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <Page title="Settings">
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <Grid item xs={12} sm={6}>
          <form 
          // onSubmit={handleSubmit}
          >
            <Typography variant="h6" align="center" gutterBottom>
              Profile Settings
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="profile_image"
                  // onChange={handleFileChange}
                />
                <div
                  className="edit-profile-icon"
                  style={{ textAlign: "center" ,position:"relative" }}
                >
                  <Avatar
                    // alt="Profile"
                    // src={
                    //   s3baseUrl + (image   ? image   : oldImage   ? oldImage  : profile.profile_image)}
                    sx={{ width: 100, height: 100 }}
                  />
                  <div style={{
                    position:'absolute',
                    right:'5px',
                    bottom:'1px',
                    color:'white',
                    // backgroundColor: (theme) => theme.palette.primary.main,
                    backgroundColor:'#655FB1',
                    padding:'4px',
                    borderRadius:'50%',
                    cursor:'pointer',
                  }}> 
                    <PhotoCameraIcon className="edit-profile-icon-position" />
                  </div>
                </div>
              </label>
              <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  required
                  // name="first_name"
                  // value={userData.first_name}
                  // onChange={handleChange}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  required
                  // name="last_name"
                  // value={userData.last_name}
                  // onChange={handleChange}
                />
              </FormControl>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className="small-contained-button"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </Button>
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Settings;
