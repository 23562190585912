import React, { useState, useEffect } from "react";
import { Page } from "src/components";
import { useSnackbar } from "notistack";
import {
  Container,
  Typography,
  Stack,
  Grid,
  TextField,
  FormControl,
  Backdrop,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Autocomplete,
} from "@mui/material";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import LoadingButton from "@mui/lab/LoadingButton";
import CkEditor from "src/components/Editor";
import { getSettings, setSettings } from "src/DAL/settings";
import { getCategories } from "src/DAL/category";
import DatePickerField from "src/components/Datepicker";
import TimePicker from "src/components/TimePicker";
import moment from "moment";
import { TimeZones } from "src/constants";

function AppSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState();
  const [termsOfUseLink, setTermsOfUseLink] = useState();
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [termsOfUse, setTermsOfUse] = useState();
  const [adminEmail, setAdminEmail] = useState();
  const [defaultCategory, setDefaultCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [categories, setCategories] = useState();
  const [appUpdate, setAppUpdate] = useState();

  const [isDisable, setIsDisable] = React.useState(false);
  const [android_force_update, setAndroid_force_update] = React.useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = React.useState(false);
  const [android_app_version, setAndroid_app_version] = React.useState("");
  const [ios_force_update, setIos_force_update] = React.useState(false);
  const [ios_app_version, setIos_app_version] = React.useState("");
  const [time_zone, setTime_zone] = useState("");
  const [notify_daily_meditation, setNotify_daily_meditation] = useState({
    day: {
      is_notify: false,
      time: "",
    },
    evening: {
      is_notify: false,
      time: "",
    },
    night: {
      is_notify: false,
      time: "",
    },
  });

  const handleChangeAndroid = (e) => {
    setAndroid_app_version(e.target.value);
  };

  const handleChangeIos = (e) => {
    setIos_app_version(e.target.value);
  };

  const handleTimeZone = (e, value) => {
    // console.log(value , "timezone")
    setTime_zone(value);
  };

  const getInfo = async () => {
    try {
      const response = await getSettings();
      if (response.code === 200) {
        setPrivacyPolicyLink(response.setting.privacy_policy_link);
        setTermsOfUseLink(response.setting.terms_and_conditions_link);
        setDefaultCategory(response.default_category._id);
        setAdminEmail(response.setting.support_email);
        setPrivacyPolicy(response.setting.privacy_policy);
        setTermsOfUse(response.setting.terms_and_conditions);

        setAndroid_app_version(response.setting.android_app_version);
        setAndroid_force_update(response.setting.android_force_update);

        setIos_app_version(response.setting.ios_app_version);
        setIos_force_update(response.setting.ios_force_update);

        setIsMaintenanceMode(response.setting.maintenance);
        setNotify_daily_meditation(response.setting.notify_daily_meditation);
        setTime_zone(response.setting.timezone);
        setAppUpdate(response.setting.update_app_text);
        setOpenBackdrop(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenBackdrop(false);
    }
  };

  const getAllCategories = async () => {
    const response = await getCategories(0, 500);
    if (response.code === 200) {
      setDefaultCategory(
        response.category.find((cat) => cat.is_default === true)?._id
      );
      setCategories(response.category);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // let tempDailyMeditation={}
    // if(notify_daily_meditation.day.is_notify){
    // tempDailyMeditation={...tempDailyMeditation,day:notify_daily_meditation.day}
    // }

    // if(notify_daily_meditation.evening.is_notify){
    //   tempDailyMeditation={...tempDailyMeditation,evening:notify_daily_meditation.evening}
    // }

    // if(notify_daily_meditation.night.is_notify){
    //   tempDailyMeditation={...tempDailyMeditation,night:notify_daily_meditation.night}
    // }

    const response = await setSettings({
      support_email: adminEmail,
      default_category_id: defaultCategory,
      privacy_policy_link: privacyPolicyLink,
      terms_and_conditions_link: termsOfUseLink,
      terms_and_conditions: termsOfUse,
      privacy_policy: privacyPolicy,
      android_app_version: android_app_version,
      android_force_update: android_force_update,
      ios_app_version: ios_app_version,
      ios_force_update: ios_force_update,
      maintenance: isMaintenanceMode,
      notify_daily_meditation: notify_daily_meditation,
      timezone: time_zone,
      update_app_text: appUpdate,
    });
    if (response.code === 200) {
      setLoading(false);
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  return (
    <Page title="App Settings">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!openBackdrop && (
        <Container maxWidth="xl" className="mb-5">
          <Typography variant="h4" sx={{ mb: 5 }}>
            App Settings
          </Typography>
          <Stack spacing={2} direction="row" justifyContent="center">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      value={adminEmail}
                      type="email"
                      id="outlined-basic"
                      label="Admin Email"
                      variant="outlined"
                      onChange={(e) => setAdminEmail(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Default Category*
                    </InputLabel>
                    {console.log(defaultCategory, "asasasa2ssasa")}
                    <Select
                      // required
                      label="Default Category"
                      value={defaultCategory}
                      onChange={(e) => {
                        setDefaultCategory(e.target.value);
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 40 * 4.5 + 5,
                            width: 100,
                          },
                        },
                      }}
                    >
                      {categories &&
                        categories.map((category) => {
                          return (
                            <MenuItem key={category._id} value={category._id}>
                              {category.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      type="url"
                      value={privacyPolicyLink}
                      id="outlined-basic"
                      label="Privacy policy link"
                      variant="outlined"
                      onChange={(e) => setPrivacyPolicyLink(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      type="url"
                      value={termsOfUseLink}
                      id="outlined-basic"
                      label="Terms of use link"
                      variant="outlined"
                      onChange={(e) => setTermsOfUseLink(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography mb={2} variant="h5">
                    App Update
                  </Typography>
                  {/* <TextField
                      multiline
                      rows={5}
                      required
                      placeholder="What's new update ..."
                      value={appUpdate}
                      id="outlined-basic"
                      label="App Update"
                      variant="outlined"
                      onChange={(e) => setAppUpdate(e.target.value)}
                    /> */}
                  <FormControl fullWidth>
                    <CkEditor
                      Placeholder="What's new update ..."
                      data={appUpdate}
                      setData={setAppUpdate}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Privacy policy</Typography>
                  <FormControl fullWidth>
                    <CkEditor
                      Placeholder="Enter privacy policy here"
                      data={privacyPolicy}
                      setData={setPrivacyPolicy}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Terms of use</Typography>
                  <FormControl fullWidth>
                    <CkEditor
                      Placeholder="Enter terms of use here"
                      heading="Terms of use"
                      data={termsOfUse}
                      setData={setTermsOfUse}
                    />
                  </FormControl>
                </Grid>

                {/* date_notifications */}

                <Grid item xs={6} direction="column">
                  <Grid item xs={6}>
                    {/* <div className="d-flex justify-content-between align-items-center mb-3"> */}
                    <div className="row" style={{ marginBottom: "6%" }}>
                      <div className="col-4 d-flex align-items-center">
                        <Typography color="textSecondary">
                          Meditation of the day:
                        </Typography>
                      </div>
                      <div className="col-4 d-flex justify-content-start align-items-center">
                        <FormControlLabel
                          // style={{ marginLeft: 5 }}
                          control={
                            <Switch
                              checked={notify_daily_meditation.day.is_notify}
                              onChange={(e) =>
                                setNotify_daily_meditation((prev) => {
                                  return {
                                    ...prev,
                                    day: {
                                      ...prev.day,
                                      is_notify: e.target.checked,
                                    },
                                  };
                                })
                              }
                              // name="android_force_update"
                              color="primary"
                            />
                          }
                        />
                      </div>
                      <div className="col-4 d-flex align-items-center">
                        {notify_daily_meditation.day.is_notify == true ? (
                          <TimePicker
                            style={{ width: "20px" }}
                            label="Time"
                            value={notify_daily_meditation.day.time}
                            setValue={(val) => {
                              setNotify_daily_meditation((prev) => {
                                return {
                                  ...prev,
                                  day: {
                                    ...prev.day,
                                    time: val,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div className="row " style={{ marginBottom: "6%" }}>
                      {/* <div className="d-flex justify-content-between align-items-center mb-3"> */}
                      <div className="col-4  d-flex align-items-center">
                        <Typography color="textSecondary">
                          Meditation of the evening:
                        </Typography>
                      </div>
                      <div className="col-4 d-flex justify-content-start align-items-center">
                        <FormControlLabel
                          // style={{ marginLeft: 2 }}
                          control={
                            <Switch
                              checked={
                                notify_daily_meditation.evening.is_notify
                              }
                              onChange={(e) =>
                                setNotify_daily_meditation((prev) => {
                                  return {
                                    ...prev,
                                    evening: {
                                      ...prev.evening,
                                      is_notify: e.target.checked,
                                    },
                                  };
                                })
                              }
                              color="primary"
                            />
                          }
                        />
                      </div>
                      <div className="col-4  d-flex align-items-center">
                        {notify_daily_meditation.evening.is_notify == true ? (
                          <TimePicker
                            label="Time"
                            value={notify_daily_meditation.evening.time}
                            setValue={(val) => {
                              setNotify_daily_meditation((prev) => {
                                return {
                                  ...prev,
                                  evening: {
                                    ...prev.evening,
                                    time: val,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* </div> */}
                  </Grid>

                  <Grid item xs={6}>
                    {/* <div className="d-flex justify-content-between align-items-center mb-3"> */}
                    <div className="row">
                      <div className="col-4  d-flex align-items-center">
                        <Typography color="textSecondary">
                          Meditation of the night:
                        </Typography>
                      </div>
                      <div className="col-4 d-flex justify-content-start align-items-center">
                        <FormControlLabel
                          // style={{ marginLeft: 5 }}
                          control={
                            <Switch
                              checked={notify_daily_meditation.night.is_notify}
                              onChange={(e) =>
                                setNotify_daily_meditation((prev) => {
                                  return {
                                    ...prev,
                                    night: {
                                      ...prev.night,
                                      is_notify: e.target.checked,
                                    },
                                  };
                                })
                              }
                              name="android_force_update"
                              color="primary"
                            />
                          }
                        />
                      </div>
                      <div className="col-4  d-flex align-items-center">
                        {notify_daily_meditation.night.is_notify == true ? (
                          <TimePicker
                            label="Time"
                            value={notify_daily_meditation.night.time}
                            setValue={(val) => {
                              setNotify_daily_meditation((prev) => {
                                return {
                                  ...prev,
                                  night: {
                                    ...prev.night,
                                    time: val,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* </div> */}
                  </Grid>
                </Grid>

                <Grid item xs={6} direction="column">
                  <Grid item xs={6}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Typography color="textSecondary">
                        Android Force Update:
                      </Typography>
                      <FormControlLabel
                        style={{ marginLeft: 5 }}
                        control={
                          <Switch
                            checked={android_force_update}
                            onChange={(e) =>
                              setAndroid_force_update(e.target.checked)
                            }
                            name="android_force_update"
                            color="primary"
                          />
                        }
                      />
                    </div>
                    {android_force_update == true ? (
                      <Grid item xs={12}>
                        <TextField
                          onChange={handleChangeAndroid}
                          id="android_version"
                          label="Android Version"
                          fullWidth
                          variant="outlined"
                          value={android_app_version}
                          required
                          disabled={isDisable}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Typography color="textSecondary">
                        IOS Force Update:
                      </Typography>
                      <FormControlLabel
                        style={{ marginLeft: 5 }}
                        control={
                          <Switch
                            checked={ios_force_update}
                            onChange={(e) =>
                              setIos_force_update(e.target.checked)
                            }
                            name="ios_force_ipdate"
                            color="primary"
                          />
                        }
                      />
                    </div>
                    {ios_force_update == true ? (
                      <Grid item xs={12}>
                        <TextField
                          onChange={handleChangeIos}
                          id="ios_version"
                          label="IOS Version"
                          fullWidth
                          variant="outlined"
                          value={ios_app_version}
                          required
                          disabled={isDisable}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Typography color="textSecondary">
                        Maintenance Mode:
                      </Typography>
                      {console.log(isMaintenanceMode, "jashdkjhaskdjhaskjdhas")}
                      <FormControlLabel
                        style={{ marginLeft: 5 }}
                        control={
                          <Switch
                            checked={isMaintenanceMode}
                            onChange={(e) =>
                              setIsMaintenanceMode(e.target.checked)
                            }
                            name="ios_force_ipdate"
                            color="primary"
                          />
                        }
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Autocomplete
                    id="free-solo-demo"
                    options={TimeZones.sort()}
                    renderInput={(params) => (
                      <TextField {...params} label="Time Zone" />
                    )}
                    onChange={handleTimeZone}
                    value={time_zone}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className="d-flex justify-content-end align-items-center mt-3">
                    <LoadingButton
                      sx={{ width: 200 }}
                      loading={loading}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </LoadingButton>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Stack>
        </Container>
      )}
    </Page>
  );
}

export default AppSettings;
