import { Container, Typography, Grid } from "@mui/material";
import React from "react";
import { Page, RichEditor } from "src/components";
import { useAppContext } from "src/hooks";

const Profile = () => {
  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();
  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Profile
        </Typography>
      </Container>
      <Container sx={{ my: 10 }}>
        <Grid container spacing={5}>
          <Grid item xs={2}>
            <Typography variant="h6">First Name:</Typography>
          </Grid>
          <Grid item xs={4}>
            {profile.first_name}
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{my:1}}>
          <Grid item xs={2}>
            <Typography variant="h6">Last Name:</Typography>
          </Grid>
          <Grid item xs={4}>
            {profile.last_name}
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{my:1}}>
          <Grid item xs={2}>
            <Typography variant="h6">Email:</Typography>
          </Grid>
          <Grid item xs={4}>
            {profile.email}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Profile;
