import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { action_Status } from "src/DAL/supportTicket";
 

const Context = React.createContext();

const AppContext = ({ children }) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const [profile, setProfile] = useState({
    first_name: user ? user.first_name : "Logix",
    last_name: user ? user.last_name : "Admin",
    email: user ? user.user_id.email : "",
    image: user ? user.profile_image : "",
  });

  const [checked, setChecked] = useState();

  const [action , setAction]=useState('');

  const location = useLocation()

  //------------------------------------------------------------

  const _get_user_profile = () => {
    return profile;
  }; 

  const _set_user_profile = (value) => {
    setProfile(value); 
  };

  // const actionStatus = async () => {
  //   const action_resp = await action_Status();
  //   console.log("resp_action_status", action_resp);
  //   if (action_resp.code === 200) {
  //     setAction(action_resp.action_status);
  //   } else {
  //     enqueueSnackbar(count_resp.message, { variant: "error" });
  //   }
  // };

  // useEffect(()=>{
  //   actionStatus();
  // },[])

  //---------------------------------------------------------------

  const getUserData = async () => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      profile.first_name = user.first_name;
      profile.last_name = user.last_name;
      profile.email = user.user_id.email;
      profile.image = user.profile_image;
      profile.profile_image = user.profile_image;
      setProfile({ ...profile });
    }
  };

  useEffect(() => {
    getUserData();
    return () => {};
  }, []);

  useEffect(()=>{
  if(!location.pathname.includes("tracks") && localStorage.getItem("filter")){
    localStorage.removeItem("filter")
  }
  },[location])

  //-----------------------------------------------------------------------

  const bundle = {
    _get_user_profile,
    _set_user_profile,
    checked, 
    setChecked, 

    action,
    setAction,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
