import PropTypes from "prop-types";
// material
import { TableRow, TableCell, TableHead, Typography } from "@mui/material";

ListHead.propTypes = {
  headLabel: PropTypes.array,
};

export default function ListHead({ headLabel }) {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox"> */}
        {/* <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              /> */}
        {/* </TableCell> */}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : headCell.alignCenter
              ? 'center'
              : 'left'}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{headCell.label}</Typography>

            {/* <TableSortLabel
                  hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                  ) : null}
                </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
