import { invokeApi } from 'src/utils';
export const getQuoteCategories = async (page, limit, search) => {
  if(!search)
  search="";
    const requestObj = {
      path: `api/quote_category/get_quote_categories?page=${page}&limit=${limit}&search=${search}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const getQuoteCategoryDetail = async (id) => {
    const requestObj = {
      path: `api/quote_category/quote_category_detail/${id}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const addQuoteCategory = async (data) => {
  const requestObj = {
    path : `api/quote_category/add_quote_category`,
    method: 'POST',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const editQuoteCategory = async (_id, data) => {
  const requestObj = {
    path : `api/quote_category/edit_quote_category/${_id}`,
    method: 'PUT',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const deleteQuoteCategory = async (_id) => {
  const requestObj = {
    path : `api/quote_category/delete_quote_category/${_id}`,
    method: 'DELETE',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}

export const getAllQuoteCategories = async () => {
  const requestObj = {
    path : `api/quote_category/get_admin_active_quote_categories`,
    method: 'GET',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}