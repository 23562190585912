import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import {
  showAddedDataWithoutApi,
  showEditedDataWithoutApi,
  hideDeletedDataWithoutApi,
} from "src/DAL/updateWithoutApi";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Divider,
  Tooltip,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
// mock
import USERLIST from "../../_mock/user";
//
import { useNavigate } from "react-router-dom";
import EditHabitDrawer from "./components/EditHabitDrawer";
import AddHabitDrawer from "./components/AddHabitDrawer";
import { getHabits, deleteHabit } from "src/DAL/habits";
// modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 2,
};

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "order" , label:"Order" , alignRight:false},
  { id: "type", label: "Type", alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "", label: "Action", alignRight: true},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
 
export default function Users() {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [editHabitId, setEditHabitId] = useState();
  const [habits, setHabits] = useState();
  const [users, setUsers] = useState(USERLIST);
  const navigate = useNavigate();
  const [tempName, setTempName] = useState();
  const [tempStatus, setTempStatus] = useState();
  const [tempType, setTempType] = useState();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState();

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(order);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [change, setChange] = useState(true);
  const [editIndex, setEditIndex] = useState();
  const [count, setCount] = useState(0);
// modal
const [open, setOpen] = useState(false);
const [imagePath, setImagePath] = useState("");
const handleOpen = (image) => {
  setOpen(true);
  setImagePath(image);
};
const handleClose = () => setOpen(false);
// -----------
  //===============================================

  const habitsFunc = async () => {
    try {
      const res = await getHabits(page, rowsPerPage, filterName);
      if (res.code === 200) {
        setCount(res.count);
        setHabits(res.habits);

      } 
      else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    }
     catch (e) {
      console.log(e);
    } 
    finally {
      setOpenBackdrop(false);
    }
  };

  useEffect(() => {
   habitsFunc();
  }, [page, rowsPerPage, filterName]);

  useEffect(()=>{
    setOpenBackdrop(true);
  },[page,rowsPerPage])

  useEffect(()=>{
    setPage(0);
  },[filterName]);
  
  const handleEdit = () => {
    setOpenEditDrawer(true);
  };
  const handleAdd = () => {
    setOpenAddDrawer(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredHabits = habits
    ? applySortFilter(habits, getComparator(order, orderBy), filterName)
    : "";
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredHabits.length)
      : 0;

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }
  const isHabitNotFound = habits && habits.length === 0;
  // const handleNav = () => {
  //   navigate('/users/add-user');
  // };

  const handleDelete = async (id, index) => {
    try {
      const response = await deleteHabit(id);
      if (response.code === 200) {
        setCount(count-1)
        hideDeletedDataWithoutApi(index, habits, setHabits);
        enqueueSnackbar(response.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(habits);
  return (
    <Page title="Habit">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EditHabitDrawer
        showEditedDataWithoutApi={showEditedDataWithoutApi}
        habits={habits}
        setHabits={setHabits}
        index={editIndex}
        openEditDrawer={openEditDrawer}
        setOpenEditDrawer={setOpenEditDrawer}  
        editHabitId={editHabitId}
        setChange={setChange}
        change={change}
        page={page}
        rowsPerPage={rowsPerPage}
        habitsFunc={habitsFunc}
      />
      <AddHabitDrawer
        habits={habits} 
        setHabits={setHabits}
        count={count}
        setCount={setCount}
        showAddedDataWithoutApi={showAddedDataWithoutApi}
        openAddDrawer={openAddDrawer}
        setOpenAddDrawer={setOpenAddDrawer}
        setChange={setChange}
        change={change}
        habitsFunc={habitsFunc}
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }}
          >
            New Habit
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Habits"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {habits &&
                    habits
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        const { _id, name, type, status, order, images } = row;
                        return (
                          <TableRow hover key={_id} tabIndex={-1}>
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                            <Avatar
                                alt={name}
                                 src={s3baseUrl + images.small}
                                onClick={() => handleOpen(images.small)}
                              />
                            </TableCell>
                            <TableCell sx={{ maxWidth: 120 }}>
                              {/* <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              > */}
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                                     
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>

                              {/* </Stack> */}
                            </TableCell>
                            <TableCell align="left">
                                {order}
                            </TableCell>
                            <TableCell align="left">
                              {type === "to-do" ? "To-Do" : "Not To-Do"}
                            </TableCell>
                            {/* <TableCell align='left'>{company}</TableCell>
                          <TableCell align='left'>{role}</TableCell>
                          <TableCell align='left'>{isVerified ? 'Yes' : 'No'}</TableCell> */}
                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={status === true ? "success" : "error"}
                              >
                                {status === true ? "Active" : "Inactive"}
                              </Label>
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                id={_id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                setEditId={setEditHabitId}
                                index={index}
                                setEditIndex={setEditIndex}
                                title='Habit'
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isHabitNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} 
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={habits ? count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            title = 'Habit'
          />
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </div>

            <Divider className="my-2" />
            <img
              src={s3baseUrl + imagePath}
              alt="images"
              style={{ width: "100%" }}
            />
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
