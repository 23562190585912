import { invokeApi } from 'src/utils';
export const getQuotes = async (page, rows, search) => {
    const requestObj = {
      path: `api/quotes/get_quotes?page=${page}&limit=${rows}&search=${search}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const getQuoteDetail = async (id) => {
    const requestObj = {
      path: `api/quotes/quotes_detail/${id}`,
      method: 'GET',
      headers: {
        'x-sh-auth': localStorage.getItem('token')
      }
    };
    return invokeApi(requestObj);
  };
  
export const addQuote = async (data) => {
  const requestObj = {
    path : `api/quotes/add_quotes`,
    method: 'POST',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const editQuote = async (_id, data) => {
  const requestObj = {
    path : `api/quotes/edit_quotes/${_id}`,
    method: 'PUT',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
    postData : data,
  };
  return invokeApi(requestObj)
}

export const deleteQuote = async (_id) => {
  const requestObj = {
    path : `api/quotes/delete_quotes/${_id}`,
    method: 'DELETE',
    headers : {
      'x-sh-auth' : localStorage.getItem('token')
    },
  };
  return invokeApi(requestObj)
}