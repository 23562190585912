// component
import { useAppContext } from 'src/hooks';
import Iconify from '../../components/Iconify';

// -----------------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig =()=>{

const { action} = useAppContext(); 

return [ 
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },
  {
    title: 'users',
    path: '/users',
    icon: getIcon('fe:users'),
  },
  {
    title: 'sandbox users',
    path: '/sandbox_users',
    icon: getIcon('mdi:user'),
  },
  {
    title: 'Habit',
    path: '/habit',
    icon: getIcon('ri:todo-fill'),
  },
  // {
  //   title: 'Categories',
  //   // path: '/categories',
  //   icon: getIcon('carbon:collapse-categories'),
  //   children:[
  //     {
  //       title:"Track categories",
  //       icon:"",
  //       path:'/track-categories'
  //     },
  //     {
  //       title:"Quote categories",
  //       icon:"",
  //       path:'/quote-categories'
  //     },
  //   ]
  // },
  {
    title: 'Tracks',
    path: '/tracks',
    icon: getIcon('dashicons:format-audio'),
    children:[
      {
        title:"Tracks",
        path: '/tracks',
      },
      {
        title:"Category",
        path: '/track-categories',
      },
      {
        title: 'Meditation Dashboard',
        path : '/app_dashboard_setting',
        // icon: getIcon('material-symbols:music-cast')
      },
    ]
  },
  {
    title: 'Quotes',
    path : '/quotes',
    icon: getIcon('carbon:quotes'),
    children:[
      {
        title:"Quotes",
        path: '/quotes',
      },
      {
        title:"Category",
        path: '/quote-categories',
      }
    ]
  },
  {
    title: 'Feedbacks',
    path : '/support_ticket',
    icon: getIcon('material-symbols:feedback'),
    dot: action
  },
  {
    title: 'Push Notifications',
    path : '/push_notifications',
    icon: getIcon('material-symbols:notifications-active')
  },
  {
    title: 'App Settings',
    path : '/app_settings',
    icon: getIcon('clarity:settings-solid')
  },
];
}
  
export default navConfig;
 